import axios from "axios";
import { getUserToken } from "../libs/user";
import { fireReloginPopup } from "../libs/utils";

axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getUserToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (
      error?.response?.status === 401 &&
      !error.config?.url.includes("/login")
    ) {
      fireReloginPopup();
      return;
    }
    return Promise.reject(error);
  }
);

const makePostRequest = async (url, data) => {
  const config = {
    method: "post",
    url,
    data,
  };
  const response = await axios(config);
  return response;
};

const makeGetRequest = async (url) => {
  let config = {
    method: "get",
    url: url,
  };
  const response = await axios(config);
  return response;
};

const makePutRequest = async (url, data) => {
  const config = {
    method: "put",
    url,
    data,
  };
  const response = await axios(config);
  return response;
};

const makeDeleteRequest = async (url) => {
  let config = {
    method: "delete",
    url,
  };
  const response = await axios(config);
  return response;
};

export { makePostRequest, makeGetRequest, makePutRequest, makeDeleteRequest };
