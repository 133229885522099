import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthorizedUserLayout from "../pages/common/authorized-user-layout/authorized-user-layout";
import ForgetPassword from "../pages/login/forget-password/forget-password";
import Login from "../pages/login/signin/login";
import ListActiveCoupons from "../pages/offers/active-coupons/list-active-coupons";
import ListOffers from "../pages/offers/offers/list-offers";
import ListOffersStores from "../pages/offers/offersStores/list-offers-stores";
import ListPartnerPromotionalOffers from "../pages/offers/partner-promotional-offers/list-partner-promotial-offers";
import ListReward from "../pages/offers/rewards/list-reward/list-reward";
import Reward from "../pages/offers/rewards/reward/reward";
import PendingBanks from "../pages/transactions/plaid-banks-request/pendingbank";
import SaareGaamaUserData from "../pages/transactions/saaregama-user-data/saaregamauserdata";
import Users from "../pages/Users/user";
import AdvertiserCategory from "../pages/utilities/advertiser-category/advertiser-category";
import ListAdvertiserCategory from "../pages/utilities/advertiser-category/list-advertiser-category";
import ListAppConfig from "../pages/utilities/application-config/app-config";
import ListBank from "../pages/utilities/banks/ListBank";
import EditCategory from "../pages/utilities/category/edit-category";
import Listcategory from "../pages/utilities/category/list-category";
import ListFaq from "../pages/utilities/FAQ/list-faq";
import HowItWorks from "../pages/utilities/how-it-works/how-it-works";
import ListHowItWorks from "../pages/utilities/how-it-works/list-how-it-works";
import TiersList from "../pages/utilities/new-tiers/tierslist";
import ProcessTransactions from "../pages/utilities/process-transactions/process-transactions";
import EditRewardsFaq from "../pages/utilities/rewards-faq/editRewardsfaq";
import ListRewardsFaq from "../pages/utilities/rewards-faq/list-rewards-faq";
import StaticJson from "../pages/utilities/staticjson/static-json";
import TopCardsStaticJson from "../pages/utilities/staticjson/top-cards-static-json";
import Cashback from "../pages/Wallet/Cashback/cashback";
import Protected from "../pages/common/Protected";
import ListActiveCouponSingle from "../pages/offers/active-coupons/ListActiveCouponSingle";
import ListBankAddRule from "../pages/utilities/bank-add-rule-engine/ListBankAddRule";
import BankAddRule from "../pages/utilities/bank-add-rule-engine/BankAddRule";
import EditBankAddRule from "../pages/utilities/bank-add-rule-engine/EditBankAddRule";
import MerchantList from "../pages/utilities/merchants/MerchantList";
import AddMerchant from "../pages/utilities/merchants/AddMerchant";
import EditMerchant from "../pages/utilities/merchants/EditMerchant";
import CardSequenceList from "../pages/utilities/card-sequence/CardSequenceList";
import EditCardSequence from "../pages/utilities/card-sequence/EditCardSequence";
import EditOffer from "../pages/offers/offers/offers";
import EditApplicationConfig from "../pages/utilities/application-config/edit-application-config";
import AddApplicationConfig from "../pages/utilities/application-config/add-app-config";
import ListStores from "../pages/utilities/stores/listStores";
import EditOfferStores from "../pages/utilities/stores/editStores";
import AddOfferStores from "../pages/utilities/stores/addStores";
import AddOffers from "../pages/offers/offers/add-offers";
import ListOfferGroups from "../pages/offers/offer-groups/ListOfferGroups";
import EditOfferGroup from "../pages/offers/offer-groups/EditOfferGroup";
import AddOfferGroup from "../pages/offers/offer-groups/AddOfferGroup";
import Home from "../pages/Home";
import EditMerchantTransactionMapping from "../pages/utilities/txn-merchant/EditMerchantTransaction";
import ListTransactionMerchantMapping from "../pages/utilities/transaction-merchant-mapping/list-transaction-merchant-mapping";
import ListCmsUsers from "../pages/Users/list-cms-user";
import AddCmsUser from "../pages/Users/add-cmsuser";
import EditCmsUser from "../pages/Users/editcmsUser";
import ListTransaction from "../pages/transactions/transactions/transactionslist";
import EditTransactions from "../pages/transactions/transactions/edittransactions";
import EditFaq from "../pages/utilities/FAQ/edit-faq";
import AddFaq from "../pages/utilities/FAQ/add-faq";
import EditPartnerPromotionalOffers from "../pages/offers/partner-promotional-offers/edit-partner-promotial-offers";
import AddPartnerPromotionalOffers from "../pages/offers/partner-promotional-offers/add-partner-promotial-offers";
import EditOffersStores from "../pages/offers/offersStores/edit-offers-store";
import AddOffersStores from "../pages/offers/offersStores/add-offers-store";
import CashbackRequestList from "../pages/Wallet/Cashback Request/cashbackRequest";
import AddCategory from "../pages/utilities/category/add-category";
import AddHowItWorkS from "../pages/utilities/how-it-works/add-how-it-work";
import EditTiers from "../pages/utilities/new-tiers/edittiers";
import AddTiers from "../pages/utilities/new-tiers/addTiers";
import PlaidBanksList from "../pages/transactions/plaid-banks/plaidbanks";
import EditPlaidBanks from "../pages/transactions/plaid-banks/editplaidbank";
import NotFound from "../pages/errors/NotFound";
import appConfig from "../config/app.config";
import RoleAccess from "../pages/common/RoleAccess";
import ListBonusReferral from "../pages/utilities/bonus-referrals/ListBonusReferral";
import AddBonusReferral from "../pages/utilities/bonus-referrals/AddBonusReferral";
import EditBonusReferral from "../pages/utilities/bonus-referrals/EditBonusReferral";
import ListTierBenefits from "../pages/utilities/tier-benefits/ListTierBenefits";
import AddTierBenefits from "../pages/utilities/tier-benefits/AddTierBenefits";
import EditTierBenefits from "../pages/utilities/tier-benefits/EditTierBenefits";
import EditBank from "../pages/utilities/banks/EditBank";
import ResetPassword from "../pages/login/forget-password/ResetPassword";
import ListRules from "../pages/utilities/csm-rules/ListRules";
import EditRules from "../pages/utilities/csm-rules/EditRules";
import AddRules from "../pages/utilities/csm-rules/AddRules";
import FactorAuthenticate from "../pages/fa/Factor-Authenticate";
import AffiliatePartnerList from "../pages/utilities/affiliate-partner/AffiliatePartnerList";
import AppConfigurationList from "../pages/neobank/application-config/AppConfigurationList";
import AddEditAppConfiguration from "../pages/neobank/application-config/AddEditAppConfiguration";
import SearchUsers from "../pages/bank-users/SearchUsers";
import UpdatedSearchUsers from "../pages/bank-users/UpdatedSearchUsers";
import UserTxnDetails from "../pages/bank-users/UserTxnDetails";
import AddEditAffiliatePartner from "../pages/utilities/affiliate-partner/AddEditAffiliatePartner";
import RemittanceReturn from "../pages/neobank/remittance/RemittanceReturn";
import GenericImageUpload from "../pages/utilities/imageUpload/UploadedImageList";
import AddImage from "../pages/utilities/imageUpload/ImageUpload";
import FxCompaignList from "../pages/utilities/fx-rate-compaign/FxRateCampaignList";
import AddEditFxCampaign from "../pages/utilities/fx-rate-compaign/AddEditFxRate";
import BlockedUserList from "../pages/utilities/remittance-blocked-users/BlockedUsersList";
import EditBlockedUser from "../pages/utilities/remittance-blocked-users/EditBlockedUsersList";
import RiskConfigurationList from "../pages/neobank/risk-configuration/RiskConfigurationList";
import AddEditRiskConfiguration from "../pages/neobank/risk-configuration/AddEditRiskConfiguration";
import AchDetails from "../pages/neobank/ach-ledger/AchLedger";
import NeobankRiskConfigurationList from "../pages/neobank/risk-configuration/NeobankRiskconfigurationList";
import ShowTransactions from "../pages/transactions/user-plaid-history/ShowUserTransactions";
import FxCompareList from "../pages/neobank/fx-rate-compare/FxRateCompare";
import ActivityList from "../pages/utilities/timesPoint/ActivityList";
import EarnActivityList from "../pages/utilities/timesPoint/EarnActivityList";
import ProcessCashback from "../pages/Wallet/Cashback/BulkCashbackProcessing";
import SearchTransaction from "../pages/neobank/search-transaction/SearchTransaction";

import CreateActivity from "../pages/utilities/timesPoint/CreateActivity";
import CreateEditEarnActivity from "../pages/utilities/timesPoint/CreateEditEarnActivity";

function RouteController() {
  return (
    <>
      <Router basename="/">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/app" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
          <Route
            path="/app/rewards/id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <Reward />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/rewards"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListReward />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/active-coupons"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListActiveCoupons />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/active-coupons/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListActiveCouponSingle />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offers"
            element={
              <Protected>
                <AuthorizedUserLayout>
                  <ListOffers />
                </AuthorizedUserLayout>
              </Protected>
            }
          />
          <Route
            path="/app/expired-offers"
            element={
              <Protected>
                <AuthorizedUserLayout>
                  <ListOffers type="expired" />
                </AuthorizedUserLayout>
              </Protected>
            }
          />
          <Route
            path="/app/offer/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditOffer />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-partner-promotional-offers"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListPartnerPromotionalOffers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/partner-promotional-offer/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditPartnerPromotionalOffers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/partner-promotional-offer/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddPartnerPromotionalOffers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-offer-store"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListOffersStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offer-store/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddOffersStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/transactions/:page/:pagesize"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListTransaction />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/transactions"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListTransaction />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/search-transactions"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <SearchTransaction />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/plaidbanks"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <PlaidBanksList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/plaidbank/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditPlaidBanks />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/pending-bank"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <PendingBanks />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/saaregama-user-datas"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <SaareGaamaUserData />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/transaction/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditTransactions />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/user"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <Users />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/cashback"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <Cashback />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/pending-cashback"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <CashbackRequestList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-advertiser-category"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListAdvertiserCategory />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/advertiser-category/id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AdvertiserCategory />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-category"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <Listcategory />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/category/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditCategory />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/category/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddCategory />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-how-it-works"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListHowItWorks />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/how-it-works/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <HowItWorks />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/how-it-works/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddHowItWorkS />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/how-it-works/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddHowItWorkS />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-faq"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListFaq />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/faq/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddFaq />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/faq/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditFaq />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-card-sequence"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <CardSequenceList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/card-sequence/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditCardSequence />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-rewards-faq"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListRewardsFaq />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/rewards-faq/id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditRewardsFaq />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-app-config"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListAppConfig />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/static-json"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <StaticJson />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/top-cards-json"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <TopCardsStaticJson />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/process-transactions"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ProcessTransactions />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-benefits"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListTierBenefits />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/benefit/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddTierBenefits />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/benefit/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditTierBenefits />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/tiers"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <TiersList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/tier/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditTiers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/tier/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddTiers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          {/* <Route
            path="/app/list-benefits"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListBenefits />
                </Protected>
              </AuthorizedUserLayout>
            }
          /> */}
          <Route
            path="/app/banks"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListBank />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/bank/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditBank />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-bank-add-rule"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListBankAddRule />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/bank-add-rule-engine/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditBankAddRule />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/bank-add-rule-engine/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <BankAddRule />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-merchants"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <MerchantList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/merchant/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditMerchant />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/merchant/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddMerchant />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/app-config/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditApplicationConfig />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/app-config/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddApplicationConfig />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/store/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditOfferStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/store/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddOfferStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offer/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddOffers />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-stores"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-offer-groups"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListOfferGroups />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offer-group/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditOfferGroup />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offer-group/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddOfferGroup />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/offer-store/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditOffersStores />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/transaction-merchant-mapping-audit/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditMerchantTransactionMapping />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-transaction-merchant-mapping-audit/:page/:pagesize"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListTransactionMerchantMapping />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-cms-users"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess
                    roles={[
                      appConfig.roles.admin,
                      appConfig.roles.neobank_admin,
                    ]}
                  >
                    <ListCmsUsers />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/cms-user/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess
                    roles={[
                      appConfig.roles.admin,
                      appConfig.roles.neobank_admin,
                    ]}
                  >
                    <AddCmsUser />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/cms-user/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess
                    roles={[
                      appConfig.roles.admin,
                      appConfig.roles.neobank_admin,
                    ]}
                  >
                    <EditCmsUser />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-bonus-referal"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListBonusReferral />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/bonus-referal/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddBonusReferral />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/bonus-referal/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditBonusReferral />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/rules"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ListRules />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/rules/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditRules />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/rules/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddRules />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route path="/app/auth" element={<FactorAuthenticate />} />

          <Route
            path="/app/affiliate-partner"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AffiliatePartnerList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path={
              "id" ? "/app/affiliate-partner/:id" : "/app/affiliate-partner/0"
            }
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddEditAffiliatePartner />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/neobank-app-config"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <AppConfigurationList />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path={
              "id" ? "/app/neobank-app-config/:id" : "/app/neobank-app-config/0"
            }
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <AddEditAppConfiguration />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/fx-rate-campaign"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <FxCompaignList />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/fx-rate-compare"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <FxCompareList />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path={
              "id" ? "/app/fx-rate-campaign/:id" : "/app/fx-rate-campaign/0"
            }
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <AddEditFxCampaign />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          {/* <Route
            path="/app/fx-rate-campaign"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <FxCompaignList />
                </Protected>
              </AuthorizedUserLayout>
            }
          /> */}

          <Route
            path="/app/remittance-return"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <RemittanceReturn />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/search-users"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <SearchUsers />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/updated-search-users"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess roles={[appConfig.roles.neobank_admin]}>
                    <UpdatedSearchUsers />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/user-txn-details/:email/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <UserTxnDetails />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/user-ach-data/retry-details/:email"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AchDetails />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/image-upload"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <GenericImageUpload />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/image-upload/add"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddImage />
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/cashback-processing"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ProcessCashback />
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/blocked-remittance-users"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess
                    roles={[
                      appConfig.roles.admin,
                      appConfig.roles.neobank_admin,
                    ]}
                  >
                    <BlockedUserList />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/blocked-user/:email"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RoleAccess
                    roles={[
                      appConfig.roles.admin,
                      appConfig.roles.neobank_admin,
                    ]}
                  >
                    <EditBlockedUser />
                  </RoleAccess>
                </Protected>
              </AuthorizedUserLayout>
            }
          />

          <Route
            path="/app/risk-configuration"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <RiskConfigurationList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/neobank-risk-configuration"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <NeobankRiskConfigurationList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path={
              "risk_profile"
                ? "/app/risk-configuration/:risk_profile"
                : "/app/risk-configuration/0"
            }
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddEditRiskConfiguration />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/show-transactions"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ShowTransactions />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-activities"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <ActivityList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/activity/:spacode"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <CreateActivity />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/list-earn-activities"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EarnActivityList />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/earn-activity/:activityId"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <CreateEditEarnActivity />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          {/* <Route
            path="/app/activities/:id"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <EditMerchant />
                </Protected>
              </AuthorizedUserLayout>
            }
          />
          <Route
            path="/app/activities/0"
            element={
              <AuthorizedUserLayout>
                <Protected>
                  <AddMerchant />
                </Protected>
              </AuthorizedUserLayout>
            }
          /> */}
        </Routes>
      </Router>
    </>
  );
}

export default RouteController;
