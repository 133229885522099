import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { Form, Input, Select, Tooltip, Button, Row, Col, Collapse } from "antd";
import {
  createActivity,
  getEarnActivityDetails,
  updateActivity,
} from "../../../services/timesPoint/tpEarnActivity";
import { startLoader, stopLoader } from "../../../libs/utils";

const { Panel } = Collapse;
const { Option } = Select;

function CreateEditEarnActivity() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();
  const { activityId } = useParams();
  const isEditMode = activityId !== "0";

  const getActivityDetails = async () => {
    if (activityId !== "0") {
      startLoader();
      const earnActivityDataRes = await getEarnActivityDetails(activityId);
      const { success, data, error } = earnActivityDataRes || {};
      stopLoader();
      if (success) {
        formRef?.current?.setFieldsValue({
          ...data,
          channel: data?.channel || "TOI",
        });
        return;
      }
      Swal.fire({
        title: "Oops",
        text: error,
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/list-earn-activities");
        }
      });
    }
  };

  useEffect(() => {
    getActivityDetails();
  }, []);

  const submitProduct = async (values) => {
    setIsSubmitting(true);
    const createEditActivityRes = isEditMode
      ? await updateActivity({
          ...values,
          id: activityId,
        })
      : await createActivity(values);
    const { success, error } = createEditActivityRes || {};
    setIsSubmitting(false);
    if (success) {
      Swal.fire({
        title: "Success",
        text: "Activity has been saved successfully",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/list-earn-activities");
        }
      });
      return;
    }
    Swal.fire({
      title: "Oops",
      text: error,
      icon: "warning",
      confirmButtonText: "Ok",
    });
  };

  const renderChannels = () => {
    const channels = [
      {
        name: "abound",
        desc: "ABOUND",
      },
      {
        name: "TOI",
        desc: "TOI",
      },
    ];
    return (channels || []).map((item, index) => {
      return (
        <Option value={item.name} key={index}>
          {item.desc}
        </Option>
      );
    });
  };

  const isAHttpLink = (value) => {
    const urlRegex = /^(https?:\/\/[^\s]+)$/i;
    return urlRegex.test(value);
  };

  const validateDeepLinkUrl = (_, value) => {
    if (value) {
      if (!isAHttpLink(value)) {
        return Promise.reject(
          new Error("Invalid URL format. Must start with http:// or https://")
        );
      }
    }
    return Promise.resolve();
  };

  const validateImageUrl = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter an image URL"));
    }
    if (!isAHttpLink(value)) {
      return Promise.reject(
        new Error("Invalid URL format. Must start with http:// or https://")
      );
    }
    return Promise.resolve();
  };

  const allowString = (fieldName) => {
    return (_, value) => {
      if (!value) {
        return Promise.reject(new Error(`${fieldName} is required`));
      }

      const alphanumericRegex = /^[a-zA-Z\s]*$/;
      if (!alphanumericRegex.test(value)) {
        return Promise.reject(
          new Error(`${fieldName} must only contain letters, and spaces`)
        );
      }

      return Promise.resolve();
    };
  };

  const validateNumber = (fieldName) => {
    return (_, value) => {
      if (value === null || value === undefined || value === "") {
        return Promise.reject(new Error(`${fieldName} is required`));
      }
      if (/^-?\d+(\.\d{1,2})?$/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`${fieldName} must be a valid number`));
    };
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to={"/app/list-earn-activities"}>
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            {isEditMode ? "Edit Activity" : "Add Activity"}
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <div>
            <Form
              style={{ marginTop: "35px" }}
              layout={"vertical"}
              ref={formRef}
              name="control-ref"
              onFinish={submitProduct}
            >
              <Row gutter={[20, 0]}>
                <Col span={8}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      { required: true, validator: allowString("Title") },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="subTitle"
                    label="Subtitle"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="channel"
                    rules={[{ required: true }]}
                    label={
                      <Tooltip
                        placement="topLeft"
                        title="Choose an activity hannel"
                      >
                        Activity Channel
                      </Tooltip>
                    }
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={isEditMode}
                    >
                      {renderChannels()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="image"
                    label="Image URL"
                    rules={[{ required: true, validator: validateImageUrl }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="deepLink"
                    label="Deep Link"
                    rules={[
                      { required: false, validator: validateDeepLinkUrl },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="actionButtonTitle"
                    label="Action button title"
                    rules={[
                      {
                        required: true,
                        validator: allowString("Action button title"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="displayPoints"
                    label="Display Points"
                    rules={[
                      {
                        required: true,
                        validator: validateNumber("Display Points"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="sequenceOrder"
                    label="Sequence order"
                    rules={[
                      {
                        required: true,
                        validator: validateNumber("Sequence order"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Collapse accordion defaultActiveKey={["1"]}>
                    <Panel header="Additional Configuration" key="1">
                      <Row gutter={[20, 0]} key={"addiConfig"}>
                        {/* <Col span={4}>
                          <Form.Item
                            name="starActivity"
                            label="Star activity"
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col> */}
                        <Col span={24}>
                          <Form.Item
                            name="clickEvent"
                            label="Click Event"
                            rules={[{ required: false }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  style={{ marginTop: "25px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default CreateEditEarnActivity;
