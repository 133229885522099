import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { updateTabDetails } from "../../services/user";
import Swal from "sweetalert2";
import { TABS } from "../../constants";

function UpdateTabs({ tabDetails = [], id, setTabDetails }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const getSelectedRoles = (data) => {
    const selectedRoles = [];
    for (let role of TABS) {
      if (data[role]) {
        selectedRoles.push(role);
      }
    }
    return selectedRoles;
  };

  useEffect(() => {
    reset({ ...tabDetails });
  }, [tabDetails]);

  const updateCMSusers = (data) => {
    const selectedFeatures = getSelectedRoles(data);
    if (!selectedFeatures.length) {
      Swal.fire({
        title: "Oops",
        text: "Please select at least one role to update tabs.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    updateTabDetails(id, data)
      .then((response) => {
        setTabDetails(response.data);
        Swal.fire({
          title: "Success",
          text: "Updated successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: error?.data?.error?.message || "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  return (
    <Box sx={{ background: "#ffffff", p: 0, mb: 4, pb: 4 }}>
      <form onSubmit={handleSubmit(updateCMSusers)}>
        <div className="row">
          {TABS.map((role) => (
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="roles"
                    style={{ lineHeight: "1" }}
                  >
                    {role}
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    {...register(`${role}`)}
                    className="form-check-input"
                    type="checkbox"
                    id={role}
                    style={{ marginLeft: "35px" }}
                    disabled={role === "REMITTANCE"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr style={{ height: "0.5px", marginTop: "32px" }} />
        <div>
          <button
            type="submit"
            className="btn btn-primary py-2 px-5"
            name="save"
          >
            Save
          </button>
        </div>
      </form>
    </Box>
  );
}

export default UpdateTabs;
