import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import RiskProfile from "./RiskProfileUpdate";
import {
  deleteRemittanceUser,
  // unBlockRemittanceUser,
} from "../../../services/blockedUsersService";
import { formatCurrency, startLoader, stopLoader } from "../../../libs/utils";
import { useState } from "react";
import { syncUserStatusFromL2 } from "../../../services/user";

const UserDetails = ({
  userDetails = {},
  riskScore,
  setRiskScore,
  remittanceLimit,
}) => {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [unblocked, setUnblocked] = useState(false);

  const sections = [
    {
      type: "kycDetails",
      title: "KYC Details",
    },
    {
      type: "userControls",
      title: "User controls",
    },
  ];

  const {
    mobileNo,
    // userStatus,
    // userOptedInBanking,
    // debitCardActivated,
    // debitCardDelivered,
    // userOptedInRemittance,
    // userBlocked,
    // cipTag,
    email,
    firstName = "",
    middleName = "",
    lastName = "",
    customerId,
    userBlocked,
    layer2UserStatus,
    userId,
    referenceId,
  } = userDetails || {};

  const KYC_STATUS_MAPPING = {
    DOCUMENTS_VALIDATION_PENDING: {
      text: "KYC documents are submitted and  waiting for approval from the partner",
      colorCode: "yellow",
    },
    ACCOUNT_CREATED: {
      text: "KYC approved ",
      colorCode: "green",
    },
    KYC_FAILED: {
      text: "Further information is required from the user to complete the KYC",
      colorCode: "red",
    },
    DOCUMENTS_REQUIRED: {
      text: "Further information is required from the user to complete the KYC",
      colorCode: "red",
    },
    REGISTERED: {
      text: "The user hasn’t started the KYC yet",
      colorCode: "grey",
    },
    KYC_FAILED: {
      text: "KYC is marked as failed by the partner",
      colorCode: "red",
    },
    KYC_RETRY_LIMIT_REACHED: {
      text: "KYC retry limit reached.",
      colorCode: "red",
    },
    DOCUMENT_UPLOAD_COMPLETED: {
      text: "KYC document is uploaded.",
      colorCode: "red",
    },
    KYC_READY: {
      text: "KYC is approved.",
      colorCode: "red",
    },
    KYC_COMPLETED: {
      text: "KYC is approved.",
      colorCode: "red",
    },
  };

  const { text, colorCode } = KYC_STATUS_MAPPING[layer2UserStatus] || {
    text: "The user hasn’t started the KYC yet",
    colorCode: "grey",
  };

  const mapping = [
    { key: "Full Name", value: `${firstName} ${middleName} ${lastName}` },
    { key: "Email", value: email },
    { key: "Mobile No", value: mobileNo },
    { key: "Neobank user ID", value: userId },
    { key: "KYC Status", cls: colorCode, value: text },
    {
      key: "Layer 2 dashboard link",
      value: `https://management.layer2financial.com/customers/${customerId}/accounts`,
      isLink: true,
    },
    { key: "Delete user", type: "deleteUser" },
    { key: "Sync L2 Status", type: "syncL2Status" },
    // { key: "Debit Card activated", value: debitCardActivated ? "Yes" : "No" },
    // { key: "Debit Card delivered", value: debitCardDelivered ? "Yes" : "No" },
    // { key: "Synapse CIP tag", value: cipTag },
    // { key: " Remittance", value: userOptedInRemittance ? "Yes" : "No" },
    // { key: "Neobank", value: userOptedInBanking ? "Yes" : "No" },
    // { key: "Blocked", value: userBlocked ? "Yes" : "No" },
  ];

  const handleDeleteUser = () => {
    deleteRemittanceUser(referenceId)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "User has been deleted successfully!",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error?.message || "Something went wrong";
        Swal.fire({
          title: "Oops",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleStatusSync = async () => {
    startLoader();
    try {
      const result = await syncUserStatusFromL2(customerId);
      stopLoader();
      const { success, error } = result || {};
      if (success) {
        Swal.fire({
          title: "Success",
          text: "User status is synced successfully from Layer2!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          navigation(`/app/updated-search-users?email=${email}`);
        });
        return;
      }
      const message = error?.message || "Something went wrong!";
      Swal.fire({
        title: "Oops",
        text: message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: error.data?.error?.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  const renderKycItems = ({ key, value, isLink, cls = "", type = "" }) => {
    if (isLink) {
      return (
        <div>
          <a href={value} target="_blank">
            Go to Dashboard
          </a>
        </div>
      );
    }
    if (key === "KYC Status") {
      return (
        <div className="kycStatus">
          <div>{value}</div>
          <div className={`kycStatusText dot ${cls}`} />
        </div>
      );
    }
    if (type === "deleteUser") {
      return (
        <div>
          <Button
            variant="contained"
            size="medium"
            color="error"
            onClick={handleDeleteUser}
          >
            Delete
          </Button>
        </div>
      );
    }
    if (type === "syncL2Status") {
      return (
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleStatusSync}
          >
            Sync
          </Button>
        </div>
      );
    }
    return <div>{value}</div>;
  };

  const renderKycDetails = () => {
    return mapping.map(
      ({ key, value, isLink = false, cls, type = "" }, index) => (
        <div className="listBox" key={key}>
          <div className="listItem">
            <div className="itemHead">{key}</div>
            {renderKycItems({ key, value, isLink, cls, type })}
          </div>
        </div>
      )
    );
  };

  const renderLimit = () => {
    const {
      daily: { remaining: dailyRemiaing, total: dailyTotal } = {},
      monthly: { remaining: monthlyRemiaing, total: monthlyTotal } = {},
      yearly: { remaining: yearlyRemiaing, total: yearlyTotal } = {},
    } = remittanceLimit || {};

    const rows = [
      {
        type: "Daily",
        limit: `${formatCurrency(
          dailyTotal,
          "en-US",
          "USD"
        )} (left: ${formatCurrency(dailyRemiaing, "en-US", "USD")})`,
      },
      {
        type: "Monthly",
        limit: `${formatCurrency(
          monthlyTotal,
          "en-US",
          "USD"
        )} (left: ${formatCurrency(monthlyRemiaing, "en-US", "USD")}`,
      },
      {
        type: "Yearly",
        limit: `${formatCurrency(
          yearlyTotal,
          "en-US",
          "USD"
        )} (left: ${formatCurrency(yearlyRemiaing, "en-US", "USD")})`,
      },
    ];

    return (
      <Table sx={{ maxWidth: 400, border: 0.5 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 0.5, background: "#F1F2F4" }}></TableCell>
            <TableCell
              sx={{ border: 0.5, background: "#F1F2F4" }}
              align="center"
            >
              Remitance limits
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" sx={{ border: 0.5 }}>
                {row.type}
              </TableCell>
              <TableCell sx={{ border: 0.5 }} align="center">
                {row.limit}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  // const handleUnblock = () => {
  //   setLoading(true);
  //   unBlockRemittanceUser(email)
  //     .then((res) => {
  //       setLoading(false);
  //       setUnblocked(true);
  //       if (res?.error?.code === "NB_0091") {
  //         Swal.fire({
  //           title: "Oops",
  //           text: "User is already unblocked",
  //           icon: "error",
  //           confirmButtonText: "Ok",
  //         });
  //         return;
  //       }
  //       Swal.fire({
  //         title: "Success",
  //         text: "User has been unblocked successfully",
  //         icon: "success",
  //         confirmButtonText: "Ok",
  //       });
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       if (error.code === "NB_0091") {
  //         Swal.fire({
  //           title: "Oops",
  //           text: "User is already unblocked",
  //           icon: "error",
  //           confirmButtonText: "Ok",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "Oops",
  //           text: "Something went wrong",
  //           icon: "error",
  //           confirmButtonText: "Ok",
  //         });
  //       }
  //     });
  // };

  const renderUserControls = () => {
    // const isBlocked = userBlocked && !unblocked;
    const isBlocked = userBlocked;
    const blockedCls = userBlocked ? "red" : "green";
    return (
      <>
        <div className="listItem">
          <div className="itemHead">Remittance blocked status</div>
          <div className="kycStatus">
            <div>{isBlocked ? "BLOCKED" : "NOT_BLOCKED"}</div>
            <div className={`kycStatusText dot ${blockedCls}`} />
            {/* {isBlocked && (
              <div className="unblockUserBtn">
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={handleUnblock}
                  disabled={loading}
                >
                  Unblock
                </Button>
              </div>
            )} */}
          </div>
        </div>

        <div className="userControls">
          <div className="itemHead">Risk Profile and limits</div>
          <div className="riskScore">
            <RiskProfile
              riskScore={riskScore}
              customerId={customerId}
              setRiskScore={setRiskScore}
            />
          </div>

          <div className="limits">
            <div className="limitsHeadingText">Limits</div>
            <div>{renderLimit()}</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="userDetails">
      {sections.map(({ type, title }) => (
        <Accordion sx={{ margin: "20px 0px" }} key={type}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {title}
          </AccordionSummary>
          <AccordionDetails>
            {type === "kycDetails" ? renderKycDetails() : renderUserControls()}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default UserDetails;
