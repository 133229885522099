import CustomTable from "../../../components/CustomTable";

const RemittanceHistory = ({ userTransactions = [] }) => {
  const columns = [
    {
      id: "transaction_tracking_id",
      label: "Tracking ID",
      minWidth: 150,
      align: "left",
      enableSort: true,
    },
    {
      id: "txn_creation_date",
      label: "Txn Creation Date",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "beneficiary_details",
      label: "Beneficiary Details",
      minWidth: 80,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "amount_usd",
      label: "Amount USD",
      minWidth: 150,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "amount_inr",
      label: "Amount INR",
      minWidth: 150,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "fx_rate",
      label: "FX Rate",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "current_state",
      label: "Current State",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "strategy",
      label: "Strategy",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 200,
      align: "center",
    },
    {
      id: "Cancel",
      label: "Cancel Txn",
      minWidth: 200,
      align: "center",
    },
  ];

  return userTransactions.length ? (
    <CustomTable
      columns={columns}
      data={userTransactions}
      defaultRowPerPage={50}
    />
  ) : (
    <div>No Data Found</div>
  );
};
export default RemittanceHistory;
