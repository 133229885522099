import React, { useEffect } from "react";
import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField, Button } from "@mui/material";
import { startLoader, stopLoader } from "../../../libs/utils";
import Swal from "sweetalert2";
import {
  getBankUserInfo,
  getTransactionDetails,
  syncTxnStatusFromL2,
} from "../../../services/user";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { REASON_CODES } from "../../../constants";

function SearchTransaction() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [trackingId, setTrackingId] = useState("");
  const [bankUserInfo, setBankUserInfo] = useState({});
  const BTN_STYLE = { height: "48px", marginLeft: "8px" };

  const {
    transaction_tracking_id,
    //transaction_ref_id,
    ach_initiated_time,
    ach_completed_time,
    clearing_account_transfer_time,
    transaction_current_status,
    remittance_transaction_strategy,
    // booked_fx_initiation_time,
    // partner_initiation_time,
    nium_transaction_initiate_time,
    nium_transaction_completed_time,
    nium_booked_amount,
    amount_usd,
    amount_inr,
    fx_rate,
    cash_in_status,
    beneficiary_name,
    account_number,
    ifsc_code,
    upi_id,
    account_type,
    paymentReasonCode,
    transaction_ref_id,
    failure_reason,
    remittance_partner,
  } = bankUserInfo || {};

  useEffect(() => {
    if (id) {
      setTrackingId(id);
      searchUser(id);
    }
  }, [id]);

  const searchUser = (tid = "") => {
    startLoader();
    if (!trackingId && !tid) {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: "Please fill tracking Id",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }
    if (trackingId || tid) {
      startLoader();
      getTransactionDetails(trackingId || tid)
        .then((response) => {
          stopLoader();
          if (response?.error) {
            setBankUserInfo({});
            Swal.fire({
              title: "Oops",
              text: response.error.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            setBankUserInfo(response);
          }
        })
        .catch((error) => {
          stopLoader();
          setBankUserInfo({});
          Swal.fire({
            title: "Oops",
            text: error.data?.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        });
    }
  };

  const syncL2TxnStatus = async () => {
    startLoader();
    try {
      const result = await syncTxnStatusFromL2(transaction_ref_id);
      stopLoader();
      const { success, error } = result || {};
      if (success) {
        setBankUserInfo({});
        Swal.fire({
          title: "Success",
          text: "Transaction status is synced successfully from Layer2!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          searchUser();
        });
        return;
      }
      const message = error?.message || "Something went wrong!";
      Swal.fire({
        title: "Oops",
        text: message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: error.data?.error?.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    if (bankUserInfo) {
      setBankUserInfo(bankUserInfo);
    }
  }, [bankUserInfo]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        {id && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "25px",
              marginTop: "20px",
              gap: "16px",
            }}
          >
            <Link to={-1}>
              <ArrowBackIcon
                fontSize="large"
                color="secondary"
                sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
              />
            </Link>
            <Typography
              variant="h5"
              component="h5"
              fontWeight={"bold"}
              color={"#3d3d3d"}
            >
              Transaction Tracking ID ({id})
            </Typography>
          </div>
        )}
        {!id && (
          <>
            <Typography
              component="h4"
              variant="h4"
              color="inherit"
              noWrap
              sx={{ mb: 2, fontSize: "22px", fontWeight: "700" }}
            >
              Search Tracking Id
            </Typography>

            <div className="searchUserHeader mb-5">
              <div>
                <TextField
                  style={{ width: "280px" }}
                  label="Tracking id"
                  variant="standard"
                  value={trackingId || ""}
                  onChange={(e) => setTrackingId(e.target.value)}
                />
                <Button
                  onClick={() => searchUser()}
                  style={BTN_STYLE}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}

        {!isEmpty(bankUserInfo) && (
          <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
            <div className="row ">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Tracking Id
                    </label>
                    <input
                      value={transaction_tracking_id}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Transaction Current Status
                    </label>
                    <input
                      value={transaction_current_status}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      ACH initiated Date
                    </label>
                    <input
                      value={
                        ach_initiated_time
                          ? moment(ach_initiated_time).format("L")
                          : ""
                      }
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      ACH completed Date
                    </label>
                    <input
                      value={
                        ach_completed_time
                          ? moment(ach_completed_time).format("L")
                          : ""
                      }
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Clearing transferred Date
                    </label>
                    <input
                      value={
                        clearing_account_transfer_time
                          ? moment(clearing_account_transfer_time).format("L")
                          : ""
                      }
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      NIUM Booked INR
                    </label>
                    <input
                      value={nium_booked_amount}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Cash In Status
                    </label>
                    <input
                      value={cash_in_status}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Remittance Transaction Strategy
                    </label>
                    <input
                      value={remittance_transaction_strategy}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Name of Beneficiary
                    </label>
                    <input
                      value={beneficiary_name}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                {upi_id && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label fw-bold text-secondary user-select-none">
                        UPI ID
                      </label>
                      <input
                        value={upi_id}
                        type="text"
                        className={`form-control `}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Transfer Reason code
                    </label>
                    <input
                      value={paymentReasonCode}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Transfer Reason
                    </label>
                    <input
                      value={REASON_CODES[paymentReasonCode]}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      FX Rate
                    </label>
                    <input
                      value={fx_rate}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Account Type
                    </label>
                    <input
                      value={account_type}
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      NIUM trxn Inititated Date
                    </label>
                    <input
                      value={
                        nium_transaction_initiate_time
                          ? moment(nium_transaction_initiate_time).format("L")
                          : ""
                      }
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      NIUM Txn Paid
                    </label>
                    <input
                      value={
                        nium_transaction_completed_time
                          ? moment(nium_transaction_completed_time).format("L")
                          : ""
                      }
                      type="text"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      USD Amount
                    </label>
                    <input
                      value={amount_usd}
                      type="decimal"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      INR Amount
                    </label>
                    <input
                      value={amount_inr}
                      type="decimal"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
                {account_number && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label fw-bold text-secondary user-select-none">
                        A/c No. (last 4)
                      </label>
                      <input
                        value={account_number}
                        type="text"
                        className={`form-control `}
                        disabled
                      />
                    </div>
                  </div>
                )}
                {ifsc_code && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label fw-bold text-secondary user-select-none">
                        IFSC Code
                      </label>
                      <input
                        value={ifsc_code}
                        type="text"
                        className={`form-control `}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Settlement Partner
                    </label>
                    <input
                      value={remittance_partner}
                      type="decimal"
                      className={`form-control `}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {failure_reason && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label fw-bold text-secondary user-select-none">
                        Failure Reason
                      </label>
                      <input
                        value={failure_reason}
                        type="decimal"
                        className={`form-control `}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {remittance_partner === "LAYER2" && (
                <div className="row">
                  <div className="col-md-6">
                    <Button
                      onClick={syncL2TxnStatus}
                      // style={BTN_STYLE}
                      variant="contained"
                    >
                      Sync Transaction Status from Layer2
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Box>
        )}
      </Box>
    </>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default SearchTransaction;
