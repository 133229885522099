import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";

import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  getFreeOffers,
  getOffersDataById,
  getStateCodes,
  OffersGroupIds,
} from "../../../services/offers";
import {
  getTiers,
  getAllCategories,
  getAllOfferGroups,
  updateOffersRecord,
} from "../../../services/offers";
import {
  editOfferGroups,
  getOfferGroupsById,
} from "../../../services/offerGroups";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { getMerchantList } from "../../../services/merchants";
import { offerModel } from "../../../models/offerDataModel";
import { API_KEY_MAPPING, OFFER_VISIBILITY_OPTIONS } from "../../../constants";
import { handleDuplicateValues } from "../../../libs/utils";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";
import ImageData from "../../../utils/DisplayImage";
import OfferGroup from "./OfferGroup";
import { getFAQList } from "../../../services/faq";
import AppModal from "../../../components/modal/AppModal";
import AddFaq from "../../utilities/FAQ/add-faq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

function EditOffer(props) {
  const navigate = useNavigate();
  const [getfreeOffers, setgetfreeOffers] = useState([]);
  const [geTtiers, setgetTiers] = useState([]);
  const [getAllOffergroups, setgetAllOffergroups] = useState([]);
  const [getAllFaqs, setAllFaqs] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [linkedGroups, setLinkedGroups] = useState("");
  const [linkedGroupsList, setLinkedGroupsList] = useState([]);
  const [geoFencingEnabled, setGeoFencingEnabled] = useState(false);
  const [addFaq, setAddFaq] = useState(false);

  const { imageData, selectedFileType } = useSelector(
    (state) => state.imageData
  );
  const [imageLinks, setimageLinks] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allcategories, setAllcategories] = useState([]);
  const [allStateCountry, setStateCountry] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm();
  const { id } = useParams();
  const [response, setResponse] = useState();
  const [mount, setMount] = useState(false);
  const linkedFaq = watch("linkedFaqs");

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #ccc",
      "&:hover": {
        border: state.isSelected && "1px solid #ccc",
      },
      boxShadow: "none",
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      maxWidth: "200px",
      fontSize: "16px",
      background: "#4154f9",
      color: "#ededed",
      paddingTop: "7px",
      paddingBottom: "7px",
      paddingLeft: "16px",
      paddingRight: "16px",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected && "1px solid #ccc",
      "&:hover": {
        background: "#f9da87",
      },
    }),
  };

  const setLinkedGroupsArray = (key) => {
    setLinkedGroupsList((prev) => {
      const map = new Map();
      prev.forEach((d, i) => {
        if (d) {
          map.set(d.id, d);
        }
      });
      const findOne = getAllOffergroups.filter((d) => d.id === key)[0];
      if (findOne) {
        map.set(key, getAllOffergroups.filter((d) => d.id === key)[0]);
      }

      const arr = [];
      for (const [key, value] of map) {
        if (value) {
          arr.push(value);
        }
      }
      return arr;
    });
  };

  const updatedSortedLinkedGroups = (index, updatedData) => {
    const temp = linkedGroupsList[index];
    temp.offerIds = updatedData;
    linkedGroupsList[index] = temp;
    setLinkedGroupsList(linkedGroupsList);
  };

  const removeLinkedGroups = (index) => {
    setLinkedGroupsList(
      linkedGroupsList.filter((d, i) => {
        return i !== index;
      })
    );
  };

  useEffect(() => {
    let temp = [...imageLinks];

    const index = temp.findIndex((object) => object.label === selectedFileType);
    if (index > -1) {
      temp.splice(index, 1, { label: selectedFileType, value: imageData });
      setimageLinks(temp);
      return;
    }
    setimageLinks([
      ...imageLinks,
      { label: selectedFileType, value: imageData },
    ]);
  }, [imageData]);

  useEffect(() => {
    if (response) {
      setValue("mappedOfferId", response.mappedOfferId);
      setValue("merchantId", response.merchantId);
      setValue("tierId", response.tierId);
    }
  }, [response, getfreeOffers, merchantList, geTtiers]);

  const handleStateChange = (event) => {
    const value = event.target.value;
    if (value) {
      const filteredAllStates = allStateCountry.filter(
        (data) => data.stateName !== value
      );
      setStateCountry(filteredAllStates);

      setSelectedStates([...selectedStates, value]);
    }
  };

  const removeStateCode = (index) => {
    const updatedSelectedStatesList = selectedStates.filter((data, i) => {
      return i !== index;
    });
    setStateCountry([
      ...allStateCountry,
      { stateName: selectedStates[index], id: Math.random() * 1000 },
    ]);
    setSelectedStates(updatedSelectedStatesList);
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    if (value) {
      const filteredAllCategories = allcategories.filter(
        (data) => data.name !== value
      );
      setAllcategories(filteredAllCategories);

      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const removeCategoryGroup = (index) => {
    const updatedSelectedCategoriesList = selectedCategories.filter(
      (data, i) => {
        return i !== index;
      }
    );
    setAllcategories([
      ...allcategories,
      { name: selectedCategories[index], id: Math.random() * 1000 },
    ]);
    setSelectedCategories(updatedSelectedCategoriesList);
  };

  useEffect(() => {
    if (allcategories) {
      const values = handleDuplicateValues(
        selectedCategories,
        allcategories,
        "name"
      );
      setAllcategories(values);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (allStateCountry) {
      const values = handleDuplicateValues(
        selectedStates,
        allStateCountry,
        "label"
      );
      setStateCountry(values);
    }
  }, [selectedStates]);

  const setImageUrl = (response) => {
    const tempImageData = [];
    if (response.logo) {
      tempImageData.push({ label: "LOGO", value: response.logo });
    }
    if (response.bigImage) {
      tempImageData.push({
        label: "BIG_IMAGE",
        value: response.bigImage,
      });
    }
    if (response.smallImage) {
      tempImageData.push({
        label: "SMALL_IMAGE",
        value: response.smallImage,
      });
    }
    if (response.smallLogoImage) {
      tempImageData.push({
        label: "SMALL_LOGO_IMAGE",
        value: response.smallLogoImage,
      });
    }
    if (response.image) {
      tempImageData.push({
        label: "IMAGE",
        value: response.image,
      });
    }
    if (response.offerWallLogoCardImage) {
      tempImageData.push({
        label: "OFFER_WALL_LOGO_CARD_IMAGE",
        value: response.offerWallLogoCardImage,
      });
    }
    setimageLinks(tempImageData);
  };

  useEffect(() => {
    getTiers()
      .then((response) => {
        setgetTiers(response);
      })
      .catch(function (error) {
        throw error;
      });

    getAllCategories()
      .then((response) => {
        setAllcategories(response);
      })
      .catch(function (error) {
        throw error;
      });
    getAllOfferGroups()
      .then((response) => {
        setgetAllOffergroups(response);
      })
      .catch(function (error) {
        throw error;
      });
    getMerchantList()
      .then((merchants) => {
        setMerchantList(merchants || []);
      })
      .catch((error) => {
        setMerchantList([]);
      });

    getFreeOffers()
      .then((response) => {
        setgetfreeOffers(response);
      })
      .catch(function (error) {
        throw error;
      });
    getStateCodes()
      .then((response) => {
        setStateCountry(response);
      })
      .catch(function (error) {
        throw error;
      });
    getOffersDataById(id)
      .then((response) => {
        setResponse(response);

        getFAQList().then((allFaqresponse) => {
          setAllFaqs(allFaqresponse);
          reset({
            advertiserName: response.advertiserName,
            autoApproval: response.autoApproval,
            skipOfferDetail: response.skipOfferDetail,
            title: response.title,
            subTitle: response.subTitle,
            promoType: response.promoType,
            shortDescription: response.shortDescription,
            description: response.description,
            commission: response.commission,
            expiryDate: response.expiryDate,
            startDate: response.startDate
              ? moment(response?.startDate)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            category: response.category,
            affiliatePartner: response.affiliatePartner,
            offerId: response.offerId,
            offerWallBigCardPosition: response.offerWallBigCardPosition,
            offerWallLogoCardPosition: response.offerWallLogoCardPosition,
            popularity: response.popularity,
            clickUrl: response.clickUrl,
            latitude: response.latitude,
            longitude: response.longitude,
            placeholder: response.placeholder,
            cashBackTitle: response.cashBackTitle,
            strikeThroughCashBackTitle: response.strikeThroughCashBackTitle,
            cashbackMaxTimes: response.cashbackMaxTimes,
            timesclubCommission: response.timesclubCommission,
            cashbackMinAmount: response.cashbackMinAmount,
            cashbackMaxAmount: response.cashbackMaxAmount,
            monthlyCashbackLimit: response.monthlyCashbackLimit,
            cashbackPriority: response.cashbackPriority,
            isActive: response.isActive,
            hideExpiryDate: response.hideExpiryDate,
            isIosOffer: response.isIosOffer,
            isAndroidOffer: response.isAndroidOffer,
            newSignUpFlow: response.newSignUpFlow,
            newSignUpText: response.newSignUpText,
            newSignUpFlowSequenceNumber: response.newSignUpFlowSequenceNumber,
            firstBankOffer: response.firstBankOffer,
            locationBased: response.locationBased,
            locationMandatory: response.locationMandatory,
            sponserTitle: response.sponserTitle,
            sponserSubTitle: response.sponserSubTitle,
            sponserLogo: response.sponserLogo,
            sponserLink: response.sponserLink,
            linked_groups: response.linked_groups,
            merchantName: response.merchantName,
            displayMerchantName: response.displayMerchantName,
            preActivated: response.preActivated,
            termsConditions: response.termsConditions,
            offerLinkExpirationDays: response.offerLinkExpirationDays,
            logo: response.logo,
            bigImage: response.bigImage,
            smallImage: response.smallImage,
            image: response.image,
            smallLogoImage: response.smallLogoImage,
            offerWallLogoCardImage: response.offerWallLogoCardImage,
            couponType: response.couponType,
            couponCode: response.couponCode,
            rewardCampaignId: response.rewardCampaignId,
            isMemberBenefit: response.isMemberBenefit,
            offerVisibilityLevel: response.offerVisibilityLevel,
            geoFencingEnabled: response.geoFencingEnabled,
            applicableStates: response?.applicableStates,
          });
          setTagList(response.tags || []);
          setSelectedCategories(response.category);
          if (response.applicableStates && response.geoFencingEnabled) {
            setSelectedStates(response.applicableStates);
          }

          const linkedFaqIds = response?.linkedFaqs || [];
          if (linkedFaqIds?.length) {
            const linkedFaqs = linkedFaqIds.map((faqId) =>
              allFaqresponse.find((faq) => faq.id === faqId)
            );
            const linkedFaqValues = linkedFaqs.map((faq) => faq.objectId);
            console.log("linkedFaqValues", linkedFaqValues);
            setValue("linkedFaqs", linkedFaqIds);
            // setLinkedFaqList(linkedFaqs);
          }
          setGeoFencingEnabled(response.geoFencingEnabled);
          setImageUrl(response);
        });
      })
      .catch(function (error) {
        throw error;
      });
  }, []);

  const handleChange = (e) => {
    const { checked } = e.target;
    setGeoFencingEnabled(checked);
  };

  useEffect(() => {
    setMount(true);
    if (getAllOffergroups.length > 0 && mount === true) {
      OffersGroupIds(id)
        .then((response) => {
          const lg = response.replace("[", "").replace("]", "").split(",");
          if (lg !== "") {
            lg.map((d) => {
              setLinkedGroupsArray(String(d).trim());
            });
          }
        })
        .catch(function (error) {
          throw error;
        });
    }
  }, [mount, getAllOffergroups]);

  const getImageDataToBeUpdated = (data) => {
    let tempObj = {};
    imageLinks.forEach((item) => {
      tempObj = { ...tempObj, [API_KEY_MAPPING[item.label]]: item.value };
    });
    return tempObj;
  };

  const updateOffer = (data, buttonType) => {
    updateOffersRecord(data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Offer has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            if (buttonType === "save") {
              navigate("/app/offers");
            }
            if (buttonType === "savegoanother") {
              navigate(`/app/offer/0`);
            }
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const editOffers = async (data) => {
    const offerDataModel = offerModel;

    data.id = id;
    data.tags = tagList;
    data.destinationUrl = response.destinationUrl;
    data.cardNo = response.cardNo;
    data.logoCardNo = response.logoCardNo;
    data.bigCardNo = response.bigCardNo;
    data.smallCardNo = response.smallCardNo;
    data.listNo = response.listNo;
    // data.autoApproval = response.autoApproval;
    data.rakutenType = response.rakutenType;
    data.offerStoreIds = response.offerStoreIds;
    data.categoryOffer = response.categoryOffer;

    data.linkedGroups = linkedGroupsList.map((group) => {
      return group.id;
    });
    // data.linkedFaqs = linkedFaqList.map((faq) => faq.id);
    data.category = selectedCategories?.map((group) => {
      return group;
    });
    data.applicableStates = geoFencingEnabled
      ? selectedStates?.map((state) => {
          return state;
        })
      : [];

    for (var key of Object.keys(offerDataModel)) {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        data[key] === null ||
        data[key] === 0 ||
        data[key] === false ||
        Array.isArray(data[key]) !== Array.isArray(offerDataModel[key])
      ) {
        data[key] = offerDataModel[key];
      }
    }

    // if (data.newSignUpFlow === false) {
    //   data.newSignUpText = "";
    //   data.newSignUpFlowSequenceNumber = 0;
    // }

    // if (String(data.tierId).trim() === "") {
    //   data.tierId = null;
    // }

    const buttonType = window.event.submitter.name;
    const imageDataToBeUpdated = getImageDataToBeUpdated(data);
    const utcDate = data.startDate
      ? moment.utc(moment(data.startDate)).format()
      : null;
    const updated = { ...imageDataToBeUpdated, startDate: utcDate || null };
    const updatelinkedGroupsListRes = [];
    const linkedGroupsListLength = linkedGroupsList?.length || 0;
    if (linkedGroupsListLength) {
      linkedGroupsList.forEach(async (item, index) => {
        try {
          const response = await editOfferGroups(item.id, item);
          updatelinkedGroupsListRes[index] = { sucess: true };
          if (
            linkedGroupsListLength === index + 1 &&
            updatelinkedGroupsListRes.every((e) => e.sucess)
          ) {
            updateOffer({ ...data, ...updated }, buttonType);
          }
        } catch (e) {
          updatelinkedGroupsListRes[index] = { sucess: false };
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
      return;
    }
    updateOffer({ ...data, ...updated }, buttonType);
  };

  const [handleTagValueChange, sethandleTagValueChange] = useState("");
  const [handleTagNameChange, sethandleTagNameChange] = useState("");

  const [tagList, setTagList] = useState([]);
  const handleTagSubmit = (e) => {
    if (handleTagValueChange !== "" && handleTagNameChange !== "") {
      setTagList((prev) => {
        const v = [
          ...prev,
          { type: handleTagValueChange, value: handleTagNameChange },
        ];
        sethandleTagValueChange("");
        sethandleTagNameChange("");
        return v;
      });
    }
  };

  const removeTag = (index) => {
    setTagList(tagList.filter((d, i) => i !== index));
  };

  const handleLinkedFaqChnage = (onChange, values) => {
    onChange(values.map((c) => c.value));
  };

  const handlAddFaq = () => {
    setAddFaq(true);
  };

  const handleCreation = (faqData) => {
    setAddFaq(false);
    setAllFaqs([...getAllFaqs, faqData]);
    setValue("linkedFaqs", [...linkedFaq, faqData.id]);
  };

  const handleCancel = () => {
    setAddFaq(false);
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/offers">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Offer
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(editOffers)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="advertiser_name"
                  >
                    Advertiser Name{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("advertiserName", { required: true })}
                    className={`form-control ${
                      errors.advertiserName && "invalid-form-input"
                    }`}
                    type="text"
                    id="advertiser_name"
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="destination_url"
                  >
                    Destination URL
                  </label>
                  <input
                    {...register("destinationUrl", { required: true })}
                    className={`form-control ${
                      errors.destinationUrl && "invalid-form-input"
                    }`}
                    type="text"
                    id="destination_url"
                  />
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <input
                    {...register("title")}
                    className={`form-control ${
                      errors.title && "invalid-form-input"
                    }`}
                    type="text"
                    id="title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="subtitle"
                  >
                    Subtitle
                  </label>
                  <input
                    {...register("subTitle")}
                    className={`form-control ${
                      errors.subTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="subtitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="promo_type"
                  >
                    Promo Type <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("promoType", { required: true })}
                    className={`form-select ${
                      errors.promoType && "invalid-form-input"
                    }`}
                    id="promo_type"
                  >
                    <option value="">Select</option>
                    <option value="o">Online</option>
                    <option value="l">Local</option>
                    <option value="b">Both</option>
                    <option value="r">Reward</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="short_description"
                  >
                    Short Description
                  </label>
                  <textarea
                    {...register("shortDescription")}
                    className={`form-control ${
                      errors.shortDescription && "invalid-form-input"
                    }`}
                    type="text"
                    id="short_description"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="description"
                  >
                    Description <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    {...register("description", { required: true })}
                    className={`form-control ${
                      errors.description && "invalid-form-input"
                    }`}
                    type="text"
                    id="description"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tiers"
                  >
                    Tiers
                  </label>
                  <select
                    {...register("tierId")}
                    className={`form-select`}
                    id="tiers"
                  >
                    <option value="">Select</option>
                    {geTtiers.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.name} | {String(value.active)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="free_offers"
                  >
                    Free Offers
                  </label>
                  <select
                    {...register("mappedOfferId")}
                    className={`form-select ${
                      errors.mappedOfferId && "invalid-form-input"
                    }`}
                    id="free_offers"
                  >
                    <option value="">Select</option>
                    {getfreeOffers.map((value, index) => (
                      <option key={value.id} value={value.id}>
                        {value.advertiserName} | {value.commission}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="commission"
                      >
                        Commission
                      </label>
                      <input
                        {...register("commission")}
                        className={`form-control`}
                        type="text"
                        id="commission"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="expiry_date"
                      >
                        Expiry Date{" "}
                        <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("expiryDate", { required: true })}
                        className={`form-control ${
                          errors.expiryDate && "invalid-form-input"
                        }`}
                        type="date"
                        id="expiry_date"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group ">
                      <div
                        className="d-flex align-items-end mt-5"
                        style={{ marginBottom: "12px" }}
                      >
                        <div className="form-groupform-check d-flex align-items-center gap-3 ">
                          <input
                            {...register("hideExpiryDate")}
                            className={`form-check-input ${
                              errors.isActive && "invalid-form-input"
                            }`}
                            type="checkbox"
                            id="hideExpiryDate"
                          />
                          <label
                            className="form-check-label fw-bold text-secondary"
                            htmlFor="hideExpiryDate"
                            style={{ lineHeight: "1" }}
                          >
                            Hide Expiry Date on UI
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="start_date"
                      >
                        Start Date <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("startDate")}
                        className={`form-control ${
                          errors.startDate && "invalid-form-input"
                        }`}
                        type="datetime-local"
                        id="start_date"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-auto mt-4">
                <div className="d-flex gap-2 flex-wrap">
                  {selectedCategories?.map((category, index) => {
                    return (
                      <span
                        key={index}
                        className="badge py-2 px-3 d-flex align-items-center gap-2"
                        style={{
                          background: "#040721",
                          color: "#f8d269",
                          fontSize: "16px",
                        }}
                      >
                        {category}
                        <span
                          className="badge bg-danger py-2 px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeCategoryGroup(index)}
                        >
                          &#x2715;
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="category"
                  >
                    Category
                  </label>
                  <select
                    className={`form-select ${
                      errors.category && "invalid-form-input"
                    }`}
                    id="category"
                    onChange={(e) => handleCategoryChange(e)}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {/* {allcategories.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))} */}
                    {allcategories?.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offerVisibilityLevel"
                  >
                    Offer Visibility Level
                  </label>
                  <select
                    {...register("offerVisibilityLevel")}
                    className={`form-select ${
                      errors.offerVisibilityLevel && "invalid-form-input"
                    }`}
                    id="offerVisibilityLevel"
                    defaultValue={
                      OFFER_VISIBILITY_OPTIONS.filter(
                        (option) => option.default
                      )[0].value
                    }
                  >
                    <option value="">Select</option>
                    {OFFER_VISIBILITY_OPTIONS.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div
                className="col-md-4 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-group form-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("autoApproval")}
                    className="form-check-input"
                    type="checkbox"
                    id="auto_approval"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="auto_approval"
                    style={{ lineHeight: "1" }}
                  >
                    Auto Approval
                  </label>
                </div>
              </div>
              <div
                className="col-md-4 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div
                  style={{ marginLeft: "12px" }}
                  className="d-flex align-items-center gap-3"
                >
                  <input
                    {...register("skipOfferDetail")}
                    className={`form-check-input ${
                      errors.isIosOffer && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="skipOffer"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="skipOffer"
                    style={{ lineHeight: "1" }}
                  >
                    Skip Offer Details
                  </label>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                  <div
                    className="col-md-6 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isActive")}
                        className={`form-check-input ${
                          errors.isActive && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="is_active"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="is_active"
                        style={{ lineHeight: "1" }}
                      >
                        Is Active
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-6 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("preActivated")}
                        className="form-check-input"
                        type="checkbox"
                        id="pre_activated"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="pre_activated"
                        style={{ lineHeight: "1" }}
                      >
                        Pre activated
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isIosOffer")}
                        className={`form-check-input ${
                          errors.isIosOffer && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="ios_offer"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="ios_offer"
                        style={{ lineHeight: "1" }}
                      >
                        iOS Offer
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isAndroidOffer")}
                        className={`form-check-input ${
                          errors.isAndroidOffer && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="android_offer"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="android_offer"
                        style={{ lineHeight: "1" }}
                      >
                        Android Offer
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isMemberBenefit")}
                        className={`form-check-input ${
                          errors.isMemberBenefit && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="isMemberBenefit"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="isMemberBenefit"
                        style={{ lineHeight: "1" }}
                      >
                        Member Offer
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="affiliate_partner"
                  >
                    Affiliate Partner{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("affiliatePartner", { required: true })}
                    className={`form-select ${
                      errors.affiliatePartner && "invalid-form-input"
                    }`}
                    id="affiliate_partner"
                  >
                    <option value="">Select</option>
                    <option value="CJ">CJ</option>
                    <option value="vcommision">vcommision</option>
                    <option value="CjProduct">CjProduct</option>
                    <option value="ImpactNetwork">ImpactNetwork</option>
                    <option value="Rakuten">Rakuten</option>
                    <option value="Rakuten CLO">Rakuten CLO</option>
                    <option value="Direct">Direct</option>
                    <option value="PapperJam">PapperJam</option>
                    <option value="Admitad">Admitad</option>
                    <option value="Coupon">Coupon</option>
                    <option value="ad_based">Ad Based</option>
                    <option value="Awin">Awin</option>
                  </select>
                </div>
              </div>
              {watch("affiliatePartner") === "Coupon" && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label fw-bold text-secondary user-select-none"
                      htmlFor="coupon_type"
                    >
                      Coupon Type <small className="text-danger fs-6">*</small>
                    </label>
                    <select
                      {...register("couponType", { required: true })}
                      className={`form-select ${
                        errors.couponType && "invalid-form-input"
                      }`}
                      id="coupon_type"
                    >
                      <option value="">Select</option>
                      <option value="Static">Static</option>
                      <option value="List">List</option>
                    </select>
                  </div>
                </div>
              )}
              {watch("affiliatePartner") === "Coupon" &&
                watch("couponType") === "Static" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="couponCode"
                      >
                        Coupon Code{" "}
                        <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("couponCode", { required: true })}
                        className={`form-control ${
                          errors.couponCode && "invalid-form-input"
                        }`}
                        type="text"
                        id="couponCode"
                      />
                    </div>
                  </div>
                )}
              {watch("affiliatePartner") === "Coupon" &&
                watch("couponType") === "List" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        style={{ minWidth: "300px" }}
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="rewardCampaignId"
                      >
                        Reward Campaign Id{" "}
                        <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("rewardCampaignId", { required: true })}
                        className={`form-control ${
                          errors.rewardCampaignId && "invalid-form-input"
                        }`}
                        type="text"
                        id="rewardCampaignId"
                      />
                    </div>
                  </div>
                )}

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerId"
                  >
                    Offer Id
                  </label>
                  <input
                    disabled={
                      ["Direct", ""].includes(watch("affiliatePartner"))
                        ? true
                        : false
                    }
                    {...register("offerId", {
                      required: ["Direct", ""].includes(
                        watch("affiliatePartner")
                      )
                        ? false
                        : true,
                    })}
                    className={`form-control ${
                      errors.offerId &&
                      (["Direct", ""].includes(watch("affiliatePartner"))
                        ? false
                        : true) &&
                      "invalid-form-input"
                    }`}
                    type="text"
                    id="offerId"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />

            <div className="row">
              <ImageUploadUtility
                uploadType="OFFER"
                name="advertiserName"
                label="File"
              />
              {/* <ImageData imageUrl={link} /> */}
              {imageLinks?.length ? (
                imageLinks.map((obj) => {
                  return (
                    <ImageData imageUrl={obj.value} fileType={obj.label} />
                  );
                })
              ) : (
                <></>
              )}
            </div>

            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} /> */}
            {/* <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_wall_big_card_position"
                  >
                    Offer Wall Big Card Position
                  </label>
                  <input
                    {...register("offerWallBigCardPosition")}
                    className={`form-control ${
                      errors.offerWallBigCardPosition && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_wall_big_card_position"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_wall_logo_card_position"
                  >
                    Offer Wall Logo Card Position
                  </label>
                  <input
                    {...register("offerWallLogoCardPosition")}
                    className={`form-control ${
                      errors.offerWallLogoCardPosition && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_wall_logo_card_position"
                  />
                </div>
              </div>
            </div> */}
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="popularity"
                  >
                    Popularity <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("popularity", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.popularity && "invalid-form-input"
                    }`}
                    type="number"
                    id="popularity"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="click_url"
                  >
                    Click URL
                  </label>
                  <input
                    {...register("clickUrl")}
                    className={`form-control ${
                      errors.clickUrl && "invalid-form-input"
                    }`}
                    type="text"
                    id="click_url"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="latitude"
                  >
                    Latitude
                  </label>
                  <input
                    {...register("latitude")}
                    className={`form-control ${
                      errors.latitude && "invalid-form-input"
                    }`}
                    type="number"
                    id="latitude"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="longitude"
                  >
                    Longitude
                  </label>
                  <input
                    {...register("longitude")}
                    className={`form-control ${
                      errors.longitude && "invalid-form-input"
                    }`}
                    type="number"
                    id="longitude"
                  />
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="d-flex gap-2 flex-wrap">
                  {selectedStates?.map((state, index) => {
                    return (
                      <span
                        key={index}
                        className="badge py-2 px-3 d-flex align-items-center gap-2"
                        style={{
                          background: "#040721",
                          color: "#f8d269",
                          fontSize: "16px",
                        }}
                      >
                        {state}
                        <span
                          className="badge bg-danger py-2 px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeStateCode(index)}
                        >
                          &#x2715;
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="applicableStates"
                  >
                    State-Country Code
                  </label>
                  <select
                    className={`form-select ${
                      errors.category && "invalid-form-input"
                    }`}
                    id="applicableStates"
                    onChange={(e) => handleStateChange(e)}
                    disabled={!geoFencingEnabled}
                  >
                    <option value="" selected>
                      Select
                    </option>

                    {allStateCountry?.map((stateCode) => (
                      <option key={stateCode.id} value={stateCode.label}>
                        {stateCode.stateName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("geoFencingEnabled")}
                    className="form-check-input"
                    type="checkbox"
                    id="geoFencingEnabled"
                    onChange={(e) => handleChange(e)}
                    checked={geoFencingEnabled}
                  />
                  <label
                    className="form-check-label fw-bold text-secondary "
                    htmlFor="geoFencingEnabled"
                    style={{ lineHeight: "1", width: "auto" }}
                  >
                    Geo-targeting
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="placeholder"
                  >
                    Placeholder
                  </label>
                  <input
                    {...register("placeholder")}
                    className={`form-control ${
                      errors.placeholder && "invalid-form-input"
                    }`}
                    type="text"
                    id="placeholder"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_title"
                  >
                    Cashback Title
                  </label>
                  <input
                    {...register("cashBackTitle")}
                    className={`form-control ${
                      errors.cashBackTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="cashback_title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="strike_through_cash_back_title"
                  >
                    Strike Through Cash Back Title
                  </label>
                  <input
                    {...register("strikeThroughCashBackTitle")}
                    className={`form-control ${
                      errors.strikeThroughCashBackTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="strike_through_cash_back_title"
                  />
                </div>
              </div>
            </div>
            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} /> */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_max_times"
                  >
                    Cashback Max Times{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("cashbackMaxTimes", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.cashbackMaxTimes && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_max_times"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="timesclub_commission"
                  >
                    Timesclub Commission
                  </label>
                  <input
                    {...register("timesclubCommission")}
                    className={`form-control ${
                      errors.timesclubCommission && "invalid-form-input"
                    }`}
                    type="number"
                    id="timesclub_commission"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_min_amount"
                  >
                    Cashback Min Amount{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("cashbackMinAmount", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.cashbackMinAmount && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_min_amount"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_max_amount"
                  >
                    Cashback Max Amount
                  </label>
                  <input
                    {...register("cashbackMaxAmount")}
                    className={`form-control ${
                      errors.cashbackMaxAmount && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_max_amount"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="monthly_cashback_limit"
                  >
                    Monthly Cashback Limit
                  </label>
                  <input
                    min="0"
                    {...register("monthlyCashbackLimit")}
                    className={`form-control ${
                      errors.monthlyCashbackLimit && "invalid-form-input"
                    }`}
                    type="number"
                    id="monthly_cashback_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_link_expiration_days"
                  >
                    Cashback Priority
                  </label>
                  <input
                    min="1"
                    defaultValue={999}
                    {...register("cashbackPriority")}
                    className={`form-control ${
                      errors.cashbackPriority && "invalid-form-input"
                    }`}
                    type="text"
                    id="offer_link_expiration_days"
                  />
                </div>
              </div>
            </div>
            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div
                className="col-md-12 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("newSignUpFlow")}
                    className={`form-check-input ${
                      errors.newSignUpFlow && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="new_signup_flow"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="new_signup_flow"
                    style={{ lineHeight: "1" }}
                  >
                    New Signup Flow
                  </label>
                </div>
              </div>
              {watch("newSignUpFlow") === true && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="new_sign_up_text"
                      >
                        New Sign Up Text
                      </label>
                      <input
                        {...register("newSignUpText")}
                        className={`form-control ${
                          errors.newSignUpText && "invalid-form-input"
                        }`}
                        type="text"
                        id="new_sign_up_text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="new_sign_up_flow_sequence_no"
                      >
                        New Sign Up Flow Sequence No
                      </label>
                      <input
                        {...register("newSignUpFlowSequenceNumber")}
                        className={`form-control ${
                          errors.newSignUpFlowSequenceNumber &&
                          "invalid-form-input"
                        }`}
                        type="number"
                        id="new_sign_up_flow_sequence_no"
                      />
                    </div>
                  </div>
                </>
              )}
            </div> */}
            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rakuten_type"
                  >
                    Rakuten Type
                  </label>
                  <select
                    {...register("rakutenType", {
                      required: true,
                    })}
                    className={`form-select ${
                      errors.rakutenType && "invalid-form-input"
                    }`}
                    id="rakuten_type"
                  >
                    <option value="">Select</option>
                    <option value="product">Product</option>
                    <option value="coupon">coupon</option>
                  </select>
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-group form-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("autoApproval")}
                    onChange={handleChangeAutoApproval}
                    className="form-check-input"
                    type="checkbox"
                    id="auto_approval"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="auto_approval"
                    style={{ lineHeight: "1" }}
                  >
                    Auto Approval
                  </label>
                </div>
              </div>
              {autoApprovals && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="all_have_keywords"
                      >
                        All Have Keywords
                      </label>
                      <textarea
                        {...register("allHaveKeyWords")}
                        className={`form-control ${
                          errors.allHaveKeyWords && "invalid-form-input"
                        }`}
                        type="text"
                        id="all_have_keywords"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="any_have_keywords"
                      >
                        Any have keywords
                      </label>
                      <textarea
                        {...register("anyHaveKeyWords")}
                        className={`form-control ${
                          errors.anyHaveKeyWords && "invalid-form-input"
                        }`}
                        type="text"
                        id="any_have_keywords"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="no_have_keywords"
                      >
                        No Have Keywords
                      </label>
                      <textarea
                        {...register("noneHaveKeyWords")}
                        className={`form-control ${
                          errors.noneHaveKeyWords && "invalid-form-input"
                        }`}
                        type="text"
                        id="no_have_keywords"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div> */}
            <hr style={{ height: "0.5px", marginTop: "32px" }} />

            <div className="row">
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("firstBankOffer")}
                    className={`form-check-input ${
                      errors.firstBankOffer && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="first_bank_offer"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="first_bank_offer"
                    style={{ lineHeight: "1" }}
                  >
                    First Bank Offer
                  </label>
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("locationBased")}
                    className={`form-check-input ${
                      errors.locationBased && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="location_based"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="location_based"
                    style={{ lineHeight: "1" }}
                  >
                    Location Based
                  </label>
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("locationMandatory")}
                    className={`form-check-input ${
                      errors.locationMandatory && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="location_mandatory"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="location_mandatory"
                    style={{ lineHeight: "1", width: "auto" }}
                  >
                    Location Mandatory
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_title"
                  >
                    Sponsor Title
                  </label>
                  <input
                    {...register("sponserTitle")}
                    className={`form-control ${
                      errors.sponserTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_sub_title_id"
                  >
                    Sponsor Sub Title Id
                  </label>
                  <input
                    {...register("sponserSubTitle")}
                    className={`form-control ${
                      errors.sponserSubTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_sub_title_id"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_logo"
                  >
                    Sponsor Logo
                  </label>
                  <input
                    {...register("sponserLogo")}
                    className={`form-control ${
                      errors.sponserLogo && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_logo"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_link"
                  >
                    Sponsor Link
                  </label>
                  <input
                    {...register("sponserLink")}
                    className={`form-control ${
                      errors.sponserLink && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_link"
                  />
                </div>
              </div>
              <>
                <div className="col-md-12 mt-4">
                  <button
                    onClick={handlAddFaq}
                    type="button"
                    className="btn btn-success rounded-right-none"
                    style={{
                      whiteSpace: "nowrap",
                      height: "3rem",
                    }}
                  >
                    Create FAQ for this offer
                  </button>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label fw-bold text-secondary user-select-none w-100">
                      Choose a FAQ
                    </label>
                    <Controller
                      control={control}
                      defaultValue={[]}
                      name="linkedFaqs"
                      render={({ field: { onChange, value, ref } }) => {
                        console.log("value", value);
                        return (
                          <Select
                            styles={selectStyle}
                            inputRef={ref}
                            closeMenuOnSelect={false}
                            value={getAllFaqs
                              .filter((faq) => value.includes(faq.id))
                              .map((faq) => ({
                                label: faq.question,
                                value: faq.id,
                              }))}
                            onChange={(val) => {
                              handleLinkedFaqChnage(onChange, val);
                            }}
                            options={getAllFaqs.map((faq) => ({
                              label: faq.question,
                              value: faq.id,
                            }))}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </>
              {!!linkedGroupsList?.length && (
                <div className="col-md-12">
                  <Accordion sx={{ margin: "20px 0px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className="col-md-12">
                        <label
                          className="form-label fw-bold text-secondary user-select-none mb-0"
                          htmlFor="linked_groups"
                          style={{ lineHeight: "1" }}
                        >
                          Linked Groups
                        </label>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <div className="col-md-12">
                          {linkedGroupsList.map((value, index) => {
                            return (
                              <OfferGroup
                                data={value}
                                index={index}
                                removeLinkedGroups={removeLinkedGroups}
                                updatedSortedLinkedGroups={
                                  updatedSortedLinkedGroups
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <select
                    className={`form-select`}
                    id="linked_groups"
                    value={linkedGroups}
                    onChange={(e) => setLinkedGroupsArray(e.target.value)}
                  >
                    <option value="">Select Linked Group</option>
                    {getAllOffergroups.map((store, index) => {
                      return (
                        <option
                          key={index}
                          value={store.id}
                        >{`${store.groupTitle} | ${store.templateType} | ${store.groupType}`}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="merchant_name"
                  >
                    Merchant Name
                  </label>
                  <input
                    {...register("merchantName")}
                    className={`form-control ${
                      errors.merchantName && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_name"
                  />
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("displayMerchantName")}
                    className="form-check-input"
                    type="checkbox"
                    id="display_merchant_name"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary "
                    htmlFor="display_merchant_name"
                    style={{ lineHeight: "1", width: "auto" }}
                  >
                    Display Merchant Name
                  </label>
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row mt-4">
              <div className="col-md-12">
                <label
                  className="form-label fw-bold text-secondary user-select-none mb-0"
                  htmlFor="merchant_name"
                  style={{ lineHeight: "1" }}
                >
                  Tags
                </label>
              </div>
              <div className="col-auto mb-2">
                <div className="d-flex gap-2 flex-wrap">
                  {tagList.map((valu, index) => (
                    <span
                      key={index}
                      className="badge py-2 px-3 d-flex align-items-center gap-2"
                      style={{
                        background: "#040721",
                        color: "#f8d269",
                        fontSize: "16px",
                      }}
                    >
                      {valu.type} | {valu.value}
                      <span
                        className="badge bg-danger py-2 px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeTag(index)}
                      >
                        &#x2715;
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <div className="form-group">
                  <select
                    value={handleTagValueChange}
                    onChange={(e) => sethandleTagValueChange(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select tag name</option>
                    <option value="normal">Normal</option>
                    <option value="location">location</option>
                    <option value="PRO">PRO</option>
                    <option value="CLO">CLO</option>
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group d-flex">
                  <input
                    value={handleTagNameChange}
                    onChange={(e) => sethandleTagNameChange(e.target.value)}
                    type="text"
                    placeholder="Enter tag value"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-1">
                <button
                  onClick={handleTagSubmit}
                  type="button"
                  className="btn btn-success rounded-right-none"
                  style={{
                    whiteSpace: "nowrap",
                    height: "3rem",
                  }}
                >
                  Add Tags
                </button>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            {/* <div className="row">
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("categoryTest")}
                    className="form-check-input"
                    type="checkbox"
                    id="category_test"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="category_test"
                    style={{ lineHeight: "1" }}
                  >
                    Category Test
                  </label>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="terms_and_conditions"
                  >
                    Terms and Conditions
                  </label>
                  <textarea
                    rows="6"
                    {...register("termsConditions")}
                    className={`form-control ${
                      errors.termsConditions && "invalid-form-input"
                    }`}
                    type="text"
                    id="terms_and_conditions"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_link_expiration_days"
                  >
                    Offer Link Expiration Days
                  </label>
                  <input
                    min="0"
                    {...register("offerLinkExpirationDays")}
                    className={`form-control ${
                      errors.offerLinkExpirationDays && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_link_expiration_days"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="merchant_id"
                  >
                    Merchant Id
                  </label>
                  <select
                    {...register("merchantId")}
                    className={`form-select ${
                      errors.merchantId && "invalid-form-input"
                    }`}
                    id="merchant_id"
                  >
                    <option value="">Select</option>
                    {merchantList.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.merchant_name} | {data.id}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
              <button
                className="btn btn-warning py-2 px-5"
                name="savegoanother"
              >
                Save And Add Another
              </button>
              <button className="btn btn-success py-2 px-5" name="savecontinue">
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
        {addFaq && (
          <AppModal sx={{ p: 4 }}>
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: theme.shadows[5],
                p: 4,
              })}
            >
              <AddFaq
                isCreatingFromOffer={true}
                handleCancel={handleCancel}
                handleCreation={handleCreation}
                offerId={id}
              />
            </Box>
          </AppModal>
        )}
      </Box>
    </>
  );
}

export default EditOffer;
