import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TextField, Button, Tabs, Tab } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import { startLoader, stopLoader } from "../../libs/utils";
import Swal from "sweetalert2";

import {
  cancelTxnService,
  getBankUserInfo,
  getUserAchData,
  getUserProfile,
  getUserRemittanceTxn,
  updateTxnRecoveryData,
} from "../../services/user";
import moment from "moment";
import { updateUserRiskProfile } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { setUserAchData } from "../../redux/slices/neobankAppConfigSlice";
import UserDetails from "./searchUser/UserDetails";
import InterestRateUpdate from "./searchUser/InterestRateUpdate";
import RiskProfileUpdate from "./searchUser/RiskProfileUpdate";
import RemittanceHistory from "./searchUser/RemittanceHistory";
import AchLedger from "./searchUser/AchLedger";

const BTN_STYLE = { height: "48px", marginLeft: "8px" };
const LINK_LEFT_STYLE = { marginLeft: "4px", fontSize: "18px" };

function UpdatedSearchUsers() {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();

  const [email, setEmail] = useState("");
  const [userTransactions, setUserTransactions] = useState([]);
  const [achData, setAchData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [bankUserInfo, setBankUserInfo] = useState({});
  const [riskScore, setRiskScore] = useState("");

  const [searchParams, setSerachParams] = useSearchParams();
  const emailParam = searchParams.get("email")?.replace(/\ /g, "+");

  const handleChange = (event, newValue) => {
    sessionStorage.setItem("pageIndex", newValue);
    const value = Number(sessionStorage.getItem("pageIndex"));
    if (value) {
      setCurrentTab(value);
    } else {
      setCurrentTab(newValue);
    }
  };
  useEffect(() => {
    const value = Number(sessionStorage.getItem("pageIndex"));
    if (value) {
      setCurrentTab(value);
    } else {
      setCurrentTab(currentTab);
    }
  }, []);

  const { user, remittanceLimit } = bankUserInfo || {};
  const { customerId, userCategory } = user || {};

  const achDataGenerator = (data) => {
    const { retry_details } = data || {};
    return {
      ...data,
      retry_details: (
        <>
          {retry_details ? (
            <div className="d-flex align-items-center txnId">
              <Link
                className="text-dark txnId"
                to={`/app/user-ach-data/retry-details/${emailParam}`}
                state={{ request_id: data.request_id }}
              >
                Show details
              </Link>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      transaction_date: moment
        .unix(data.transaction_date)
        .format("MMM DD, YYYY"),
    };
  };
  const searchUser = () => {
    startLoader();
    setCurrentTab(0);
    if (email) {
      // getUserProfile(email)
      //   .then((response) => {
      //     getBankUserInfo(response.email)
      //       .then((response) => {
      //         setBankUserInfo(response);
      //         stopLoader();
      //         if (response?.error) {
      //           setUserTransactions([]);
      //           setAchData({});
      //           Swal.fire({
      //             title: "Oops",
      //             text: response.error?.message,
      //             icon: "warning",
      //             confirmButtonText: "Ok",
      //           }).then((result) => {});
      //           return;
      //         }
      //         if (response.success) {
      //           navigation(`/app/updated-search-users?email=${email}`);
      //         }
      //       })
      //       .catch((error) => {
      //         stopLoader();
      //         if (error.status === 404) {
      //           setBankUserInfo({});
      //           setAchData({});
      //           setUserTransactions([]);
      //           Swal.fire({
      //             title: "Oops",
      //             text: "User not found",
      //             icon: "warning",
      //             confirmButtonText: "Ok",
      //           }).then((result) => {});
      //         } else {
      //           Swal.fire({
      //             title: "Oops",
      //             text: "Something went wrong",
      //             icon: "warning",
      //             confirmButtonText: "Ok",
      //           }).then((result) => {});
      //         }
      //       });
      //     stopLoader();
      //   })
      //   .catch((error) => {
      //     stopLoader();
      //     if (error.status === 404) {
      //       Swal.fire({
      //         title: "Oops",
      //         text: "User not found",
      //         icon: "warning",
      //         confirmButtonText: "Ok",
      //       });
      //     } else {
      //       Swal.fire({
      //         title: "Oops",
      //         text: "Something went wrong",
      //         icon: "warning",
      //         confirmButtonText: "Ok",
      //       });
      //     }
      //   });
      navigation(`/app/updated-search-users?email=${email}`);
    } else {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: "Please fill email/phone/userid",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
  };

  const handleCancelTxn = (transaction_ref_id) => {
    if (transaction_ref_id) {
      startLoader();
      cancelTxnService(transaction_ref_id)
        .then((response) => {
          if (response?.error) {
            stopLoader();
            Swal.fire({
              title: "Oops",
              text: response.error.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            Swal.fire({
              title: "Success",
              text: response.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              getUserRemittanceTxn(emailRef.current, false)
                .then((res) => {
                  if (res?.success) {
                    setUserTransactions(
                      res.transactions
                        ?.sort(
                          (t1, t2) =>
                            t2.transaction_created_date -
                            t1.transaction_created_date
                        )
                        ?.map((data) => dataGenerator(data))
                    );
                    stopLoader();
                  }
                })
                .catch((err) => {
                  stopLoader();
                });
            });
            return;
          }
        })
        .catch((error) => {
          stopLoader();
          setBankUserInfo({});
          Swal.fire({
            title: "Oops",
            text: error.data?.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        });
    }
  };
  const handleUnblockUser = (transaction_ref_id) => {
    if (transaction_ref_id) {
      startLoader();
      updateTxnRecoveryData(transaction_ref_id)
        .then((response) => {
          stopLoader();
          if (response?.error) {
            Swal.fire({
              title: "Oops",
              text: response.error.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            Swal.fire({
              title: "Sucess",
              text: response.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
        })
        .catch((error) => {
          stopLoader();
          setBankUserInfo({});
          Swal.fire({
            title: "Oops",
            text: error.data?.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        });
    }
  };

  const dataGenerator = (data) => {
    return {
      transaction_tracking_id: (
        <div className="d-flex align-items-center txnId">
          <Link
            className="text-dark txnId"
            to={`/app/search-transactions?id=${data.transaction_tracking_id}`}
          >
            {data.transaction_tracking_id}
          </Link>
          <span style={LINK_LEFT_STYLE}>
            <i className="fa fa-angle-double-right"></i>
          </span>
        </div>
      ),
      txn_creation_date: moment(data.transaction_created_date).format("LL"),
      beneficiary_details: data.beneficiary_name,
      amount_usd: data.amount_usd,
      amount_inr: data.amount_inr,
      fx_rate: data.fx_rate,
      current_state: data.transaction_current_status,
      current_state_time: null,
      current_state_txn_id: null,
      overall_state: null,
      strategy: data.remittance_transaction_strategy,
      signal_transaction_id: data.signal_transaction_id,
      Action: (
        <>
          {data.crm_initiated && (
            <Link style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleUnblockUser(data.transaction_ref_id)}
              >
                Paid by Stripe
              </Button>
            </Link>
          )}
        </>
      ),
      Cancel: (
        <>
          {data.cancellation_eligible && (
            <Link style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleCancelTxn(data.transaction_ref_id)}
              >
                Cancel Transaction
              </Button>
            </Link>
          )}
        </>
      ),
    };
  };

  const getNeobankDetails = (identifier) => {
    getBankUserInfo(identifier)
      .then((response) => {
        setBankUserInfo(response);
        setRiskScore(response.user.riskProfile);
        if (response?.error) {
          setUserTransactions([]);
          Swal.fire({
            title: "Oops",
            text: response.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        }
        if (response.success) {
          getUserRemittanceTxn(response.user.email)
            .then((res) => {
              emailRef.current = response.user.email;
              if (res.success) {
                setUserTransactions(
                  res.transactions
                    ?.sort(
                      (t1, t2) =>
                        t2.transaction_created_date -
                        t1.transaction_created_date
                    )
                    ?.map((data) => dataGenerator(data))
                );
              }
              getUserAchData(response.user.customerId)
                .then((response) => {
                  stopLoader();
                  setAchData(
                    response.data.map((data) => achDataGenerator(data))
                  );
                  dispatch(setUserAchData(response));
                })
                .catch((error) => {
                  stopLoader();
                });
            })
            .catch((error) => {
              stopLoader();
              Swal.fire({
                title: "Oops",
                text: error?.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
              return;
            });
        }
      })
      .catch((error) => {
        stopLoader();
        if (error.status === 404) {
          Swal.fire({
            title: "Oops",
            text: "User not found",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  useEffect(() => {
    if (emailParam) {
      const isNumber = isNaN(Number(emailParam)) ? false : true;
      startLoader();
      if (isNumber && emailParam?.length < 10) {
        getUserProfile(emailParam)
          .then((response) => {
            const { email, mobile_number } = response;
            const identifier = mobile_number || email;
            getNeobankDetails(identifier);
          })
          .catch((error) => {
            stopLoader();
            if (error.status === 404) {
              Swal.fire({
                title: "Oops",
                text: "User not found",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            } else {
              Swal.fire({
                title: "Oops",
                text: "Something went wrong",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          });
        return;
      }
      getNeobankDetails(emailParam);
    }
    setEmail(emailParam);
  }, [emailParam, location]);

  const handleInput = (e) => {
    const value = e.target.value;
    if (value.match(/^\d{10}$/g) && !value.indexOf("+1") > -1) {
      setEmail(`+1${value}`);
      return;
    }
    setEmail(value);
  };

  return (
    <>
      <Box className={`main-box dynamicTableStyle`} sx={{ px: 6 }}>
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ mb: 2, fontSize: "22px", fontWeight: "700" }}
        >
          Search User
        </Typography>

        <div className="searchUserHeader mb-5">
          <div>
            <TextField
              style={{ width: "280px" }}
              label="Email/Phone/User Id"
              variant="standard"
              value={email || ""}
              onChange={(e) => handleInput(e)}
            />
            <Button
              onClick={() => searchUser()}
              style={BTN_STYLE}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </div>
        {customerId && (
          <>
            <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{}}
              >
                <Tab
                  className="tabStyle"
                  label="User Details"
                  {...a11yProps(0)}
                />

                <Tab
                  className="tabStyle"
                  label="Remittance History"
                  {...a11yProps(1)}
                />

                <Tab
                  className="tabStyle"
                  label="Update Risk Profile"
                  {...a11yProps(2)}
                />
                <Tab
                  className="tabStyle"
                  label="Update Higher Interest"
                  {...a11yProps(3)}
                />
                <Tab
                  className="tabStyle"
                  label="Ach Ledger"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              <UserDetails
                userDetails={user}
                riskScore={riskScore}
                setRiskScore={setRiskScore}
                setSerachParams={setSerachParams}
                searchParams={searchParams}
                remittanceLimit={remittanceLimit}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <RemittanceHistory userTransactions={userTransactions} />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <RiskProfileUpdate
                riskScore={riskScore}
                customerId={customerId}
                setRiskScore={setRiskScore}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <InterestRateUpdate
                userCategory={userCategory}
                customerId={customerId}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              <AchLedger achData={achData} />
            </TabPanel>
          </>
        )}
      </Box>
    </>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default UpdatedSearchUsers;
