import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragList from "../DragList";

const OfferGroup = ({
  data,
  index,
  removeLinkedGroups,
  updatedSortedLinkedGroups,
}) => {
  const { groupTitle, templateType, groupType, offerIds } = data;

  const setList = (sortedData) => {
    updatedSortedLinkedGroups(index, sortedData);
  };

  return (
    <div>
      <Accordion sx={{ margin: "5px 0px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span
            key={index}
            className="col-md-12 badge py-2 px-3 d-flex align-items-center justify-content-between"
            style={{
              background: "#040721",
              color: "#f8d269",
              fontSize: "16px",
            }}
          >
            {`${groupTitle} | ${templateType} | ${groupType}`}
            <span
              className="badge bg-danger py-2 px-2"
              style={{ cursor: "pointer" }}
              onClick={() => removeLinkedGroups(index)}
            >
              &#x2715;
            </span>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <DragList data={offerIds} setList={setList} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default OfferGroup;
