import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getActiveOffers } from "../../../services/cashback";
import { getFaqDataById } from "../../../services/faq";
import Swal from "sweetalert2";
import { updateFaqdata } from "../../../services/faq";

function AddFaq({
  isCreatingFromOffer = false,
  offerId = "",
  handleCancel,
  handleCreation,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const [faqData, setfaqdata] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [linkedGroupsList, setLinkedGroupsList] = useState([]);
  const [linkedGroups, setLinkedGroups] = useState("");
  const [getAllOffergroups, setgetAllOffergroups] = useState([]);
  const [mount, setMount] = useState(false);
  const [storeslinked, getstoreslinked] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    if (!isCreatingFromOffer) {
      getActiveOffers()
        .then((response) => {
          setgetAllOffergroups(response);
        })
        .catch(function (error) {
          throw error;
        });
    }
  }, []);

  useEffect(() => {
    if (id && !isCreatingFromOffer) {
      getFaqDataById(id)
        .then((response) => {
          setfaqdata(response);

          if (response) {
            reset({
              question: "",
              answer: "",
              popularity: "",
              isActive: "",
              id: "",
              objectId: "",
            });
            const maped = response?.offerList.map((a) => {
              return a.id;
            });

            const mapedstring = maped.toString();
            getstoreslinked(mapedstring);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  const updateAppConfigs = (data) => {
    const buttonType = window.event.submitter.name;
    const linked_store_ids = linkedGroupsList.map((d) => {
      return d.id;
    });
    data.offerIdList = linked_store_ids;
    data.promoType = null;
    data.id = 0;
    data.objectId = null;
    if (isCreatingFromOffer) {
      data.offerIdList = [offerId];
      data.isActive = true;
    }
    setIsSubmitting(true);
    if (buttonType === "save") {
      updateFaqdata(data)
        .then((response) => {
          setIsSubmitting(false);
          if (isCreatingFromOffer) {
            handleCreation(response);
          }
          Swal.fire({
            title: "Success",
            text: "FAQ has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              if (isCreatingFromOffer) {
                return;
              }
              navigation("/app/list-faq");
            }
          });
        })
        .catch((error) => {
          setIsSubmitting(false);
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savegoanother") {
      updateFaqdata(data)
        .then((response) => {
          setIsSubmitting(false);
          Swal.fire({
            title: "Success",
            text: "FAQ has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation("/app/faq/0");
            }
          });
        })
        .catch((error) => {
          setIsSubmitting(false);
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      updateFaqdata(data)
        .then((response) => {
          setIsSubmitting(false);
          Swal.fire({
            title: "Success",
            text: "FAQ has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            const ids = response.objectId;
            navigate(`/app/faq/${ids}`);
          });
        })
        .catch((error) => {
          setIsSubmitting(false);
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  useEffect(() => {
    setMount(true);

    if (storeslinked.length > 0 && mount === true) {
      const lg = storeslinked.replace("[", "").replace("]", "").split(",");

      if (lg !== "") {
        lg.map((d) => {
          setLinkedGroupsArray(String(d).trim());
        });
      }
    }
  }, [mount, storeslinked, getAllOffergroups]);

  const setLinkedGroupsArray = (key) => {
    // setLinkedGroups(key);
    setLinkedGroupsList((prev) => {
      const map = new Map();
      prev.forEach((d, i) => {
        if (d) {
          map.set(d.id, d);
        }
      });
      const findOne = getAllOffergroups.filter((d) => d.id === key)[0];

      if (findOne) {
        map.set(key, getAllOffergroups.filter((d) => d.id === key)[0]);
      }

      const arr = [];
      for (const [key, value] of map) {
        arr.push(value);
      }
      return arr;
    });
  };

  const removeLinkedGroups = (index) => {
    setLinkedGroupsList(
      linkedGroupsList.filter((d, i) => {
        setLinkedGroups("");
        return i !== index;
      })
    );
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        {!isCreatingFromOffer && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "25px",
              marginTop: "20px",
              gap: "16px",
            }}
          >
            <Link to="/app/list-faq">
              <ArrowBackIcon
                fontSize="large"
                color="secondary"
                sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
              />
            </Link>
            <Typography
              variant="h5"
              component="h5"
              fontWeight={"bold"}
              color={"#3d3d3d"}
            >
              Add FAQ
            </Typography>
          </div>
        )}
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateAppConfigs)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="question"
                  >
                    Question <small className="text-danger fs-6">*</small>
                  </label>
                  {/* <input defaultValue={AppConfigsDataItem.data?.id} {...register("id")} type="hidden" /> */}

                  <textarea
                    {...register("question", { required: true })}
                    className={`form-control ${
                      errors.question && "invalid-form-input"
                    }`}
                    type="text"
                    id="question"
                    rows="5"
                    cols="60"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="question"
                  >
                    Answer <small className="text-danger fs-6">*</small>
                  </label>

                  <textarea
                    {...register("answer", { required: true })}
                    className={`form-control ${
                      errors.answer && "invalid-form-input"
                    }`}
                    type="text"
                    id="question"
                    rows="5"
                    cols="60"
                  ></textarea>
                </div>
              </div>
              <input {...register("id")} type="hidden" id="merchant_name" />
              <input
                {...register("objectId")}
                type="hidden"
                id="merchant_name"
              />
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="popularity"
                  >
                    Popularity <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    defaultValue={0}
                    {...register("popularity", { required: true })}
                    className={`form-control ${
                      errors.popularity && "invalid-form-input"
                    }`}
                    type="number"
                    id="popularity"
                  />
                </div>
              </div>

              {!isCreatingFromOffer && (
                <>
                  <div className="row mt-4 mb-2">
                    <div className="col-md-12">
                      <label
                        className="form-label fw-bold text-secondary user-select-none mb-0"
                        htmlFor="linked_groups"
                        style={{ lineHeight: "1" }}
                      >
                        Offer Linked:
                      </label>
                    </div>
                    {linkedGroupsList.length > 0 && (
                      <div
                        className="col-auto mb-2 "
                        style={{
                          maxHeight: "259px",
                          overflowY: "auto",
                          scrollBehavior: "smooth",
                          border: "solid 1px #ccc",
                          marginLeft: "12px",
                          paddingTop: "9px",
                          paddingBottom: "9px",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="d-flex gap-2 flex-wrap">
                          {linkedGroupsList.map((value, index) => {
                            return (
                              <span
                                key={index}
                                className="badge py-2 px-3 d-flex align-items-center gap-2"
                                style={{
                                  background: "#040721",
                                  color: "#f8d269",
                                  fontSize: "13px",
                                }}
                              >
                                {`${value.advertiserName} | ${value.affiliatePartner}  `}
                                <span
                                  className="badge bg-danger py-2 px-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeLinkedGroups(index)}
                                >
                                  &#x2715;
                                </span>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        className={`form-select`}
                        id="linked_groups"
                        value={linkedGroups}
                        onChange={(e) => setLinkedGroupsArray(e.target.value)}
                      >
                        <option value="">Select Linked Offer</option>
                        {getAllOffergroups.map((store, index) => {
                          return (
                            <option
                              key={index}
                              value={store.id}
                            >{`${store.advertiserName} (${store.affiliatePartner})`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              )}

              {!isCreatingFromOffer && (
                <div
                  className="col-md-3 d-flex align-items-end"
                  style={{ marginTop: "32px" }}
                >
                  <div className="form-groupform-check d-flex align-items-center gap-4 ">
                    <input
                      {...register("isActive")}
                      className={`form-check-input`}
                      type="checkbox"
                      id="is_active"
                      defaultChecked={faqData?.isActive}
                    />
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="is_active"
                      style={{ lineHeight: "1" }}
                    >
                      Is Active
                    </label>
                  </div>
                </div>
              )}
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type={"submit"}
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Save"}
              </button>
              {isCreatingFromOffer && (
                <button
                  className="btn btn-danger py-2 px-5 mx-2"
                  name="Cancel"
                  variant="contained"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              )}
              {!isCreatingFromOffer && (
                <>
                  <button
                    variant="contained"
                    type="submit"
                    className="btn btn-primary py-2 px-5"
                    name="savegoanother"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Save And Add Another"}
                  </button>
                  <button
                    variant="contained"
                    type="submit"
                    className="btn btn-primary py-2 px-5"
                    name="savecontinue"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? "Submitting..."
                      : "Save and Continue Editing"}
                  </button>
                </>
              )}
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddFaq;
