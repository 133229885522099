import { makeGetRequest, makePostRequest } from "../makeRequest";
import appConfig from "../../config/app.config";

export const getAllActivities = async () => {
  try {
    const allActivitiesData = await makeGetRequest(
      appConfig.apiUrl.earnActivityList
    );
    return allActivitiesData.data;
  } catch (error) {
    return error?.response.data || error?.response;
  }
};
export const getEarnActivityDetails = async (activityId) => {
  try {
    const earnActivityData = await makeGetRequest(
      `${appConfig.apiUrl.earnActivityDetails}/${activityId}`
    );
    return earnActivityData.data;
  } catch (error) {
    return error?.response.data || error?.response;
  }
};

export const updateActivity = async (data) => {
  try {
    const updatedData = await makePostRequest(
      appConfig.apiUrl.updateEarnActivity,
      data
    );
    return updatedData.data;
  } catch (error) {
    return error?.response.data || error?.response;
  }
};

export const createActivity = async (data) => {
  try {
    const activityData = await makePostRequest(
      appConfig.apiUrl.createEarnActivity,
      data
    );
    return activityData.data;
  } catch (error) {
    return error?.response.data || error?.response;
  }
};
