import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getFreeOffers } from "../../../services/offers";
import {
  getTiers,
  getAllCategories,
  getAllOfferGroups,
  updateOffersRecord,
} from "../../../services/offers";
import { useForm, Controller } from "react-hook-form";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "react-select";
import Swal from "sweetalert2";
import { getMerchantList } from "../../../services/merchants";
import { offerModel } from "../../../models/offerDataModel";
import { OFFER_VISIBILITY_OPTIONS } from "../../../constants";
import OfferGroup from "./OfferGroup";
import { editOfferGroups } from "../../../services/offerGroups";

function AddOffers(props) {
  const navigate = useNavigate();
  const [getfreeOffers, setgetfreeOffers] = useState([]);
  const [geTtiers, setgetTiers] = useState([]);
  const [getAllcategories, setgetAllcategories] = useState([]);
  const [getAllOffergroups, setgetAllOffergroups] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [linkedGroupsList, setLinkedGroupsList] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #ccc",
      "&:hover": {
        border: state.isSelected && "1px solid #ccc",
      },
      boxShadow: "none",
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      maxWidth: "200px",
      fontSize: "16px",
      background: "#4154f9",
      color: "#ededed",
      paddingTop: "7px",
      paddingBottom: "7px",
      paddingLeft: "16px",
      paddingRight: "16px",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected && "1px solid #ccc",
      "&:hover": {
        background: "#f9da87",
      },
    }),
  };

  useEffect(() => {
    getTiers()
      .then((response) => {
        setgetTiers(response);
      })
      .catch(function (error) {
        throw error;
      });

    getAllCategories()
      .then((response) => {
        setgetAllcategories(response);
      })
      .catch(function (error) {
        throw error;
      });
    getAllOfferGroups()
      .then((response) => {
        setgetAllOffergroups(response);
      })
      .catch(function (error) {
        throw error;
      });

    getFreeOffers()
      .then((response) => {
        setgetfreeOffers(response);
      })
      .catch(function (error) {
        throw error;
      });

    getMerchantList()
      .then((response) => {
        setMerchantList(response || []);
      })
      .catch((error) => {
        setMerchantList([]);
        throw error;
      });
  }, []);

  const createOffer = (data, buttonType) => {
    const offerDataModel = offerModel;

    data.tags = tagList;
    if (data.category) {
      data.category = [data.category];
    }

    for (var key of Object.keys(offerDataModel)) {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        data[key] === null ||
        data[key] === 0 ||
        data[key] === false ||
        Array.isArray(data[key]) !== Array.isArray(offerDataModel[key])
      ) {
        data[key] = offerDataModel[key];
      }
    }

    // data.destinationUrl = "";
    // data.cardNo = 0;
    // data.logoCardNo = 0;
    // data.bigCardNo = 0;
    // data.smallCardNo = 0;
    // data.listNo = 0;
    // data.autoApproval = null;
    // data.rakutenType = null;
    // data.offerStoreIds = null;
    // data.categoryOffer = false;

    // if (data.newSignUpFlow === false) {
    //   data.newSignUpText = "";
    //   data.newSignUpFlowSequenceNumber = 0;
    // }

    // data.id = null;

    // if (String(data.tierId).trim() === "") {
    //   data.tierId = null;
    // }
    // return false;

    if (buttonType === "save") {
      updateOffersRecord(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/app/offers");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }

    if (buttonType === "savegoanother") {
      updateOffersRecord(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              reset();
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      updateOffersRecord(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/app/offer/${response.id}`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  const addOffers = (data) => {
    const buttonType = window.event.submitter.name;
    const updatelinkedGroupsListRes = [];
    const linkedGroupsListLength = linkedGroupsList?.length || 0;
    if (linkedGroupsListLength) {
      linkedGroupsList.forEach(async (item, index) => {
        try {
          const response = await editOfferGroups(item.id, item);
          updatelinkedGroupsListRes[index] = { sucess: true };
          if (
            linkedGroupsListLength === index + 1 &&
            updatelinkedGroupsListRes.every((e) => e.sucess)
          ) {
            createOffer(data, buttonType);
          }
        } catch (e) {
          updatelinkedGroupsListRes[index] = { sucess: false };
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
      return;
    }
    createOffer(data, buttonType);
  };

  const [handleTagValueChange, sethandleTagValueChange] = React.useState("");
  const [handleTagNameChange, sethandleTagNameChange] = React.useState("");
  const [tagList, setTagList] = useState([]);
  const handleTagSubmit = (e) => {
    if (handleTagValueChange !== "" && handleTagNameChange !== "") {
      setTagList((prev) => {
        const v = [
          ...prev,
          { type: handleTagValueChange, value: handleTagNameChange },
        ];
        sethandleTagValueChange("");
        sethandleTagNameChange("");
        return v;
      });
    }
  };

  const removeTag = (index) => {
    setTagList(tagList.filter((d, i) => i !== index));
  };

  const removeLinkedGroups = (index) => {
    const linkedGroups = watch("linkedGroups");
    const temp = linkedGroupsList.filter(
      (item) => item.id !== linkedGroups[index]
    );
    linkedGroups.splice(index, 1);
    setValue("linkedGroups", linkedGroups);
    setLinkedGroupsList(temp);
  };

  const updatedSortedLinkedGroups = (index, updatedData) => {
    const temp = linkedGroupsList[index];
    temp.offerIds = updatedData;
    linkedGroupsList[index] = temp;
    setLinkedGroupsList(linkedGroupsList);
  };

  const handleLinkedGroupChnage = (onChange, values) => {
    if (!values || values.length === 0) {
      setLinkedGroupsList([]);
      onChange([]);
      return;
    }
    const temp = values.map((item1) =>
      getAllOffergroups.find((item2) => item1.value === item2.id)
    );
    onChange(values.map((c) => c.value));
    setLinkedGroupsList(temp);
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/offers">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Offer
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(addOffers)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="advertiser_name"
                  >
                    Advertiser Name{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("advertiserName", { required: true })}
                    className={`form-control ${
                      errors.advertiserName && "invalid-form-input"
                    }`}
                    type="text"
                    id="advertiser_name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <input
                    {...register("title")}
                    className={`form-control ${
                      errors.title && "invalid-form-input"
                    }`}
                    type="text"
                    id="title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="subtitle"
                  >
                    Subtitle
                  </label>
                  <input
                    {...register("subTitle")}
                    className={`form-control ${
                      errors.subTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="subtitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="promo_type"
                  >
                    Promo Type <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("promoType", { required: true })}
                    className={`form-select ${
                      errors.promoType && "invalid-form-input"
                    }`}
                    id="promo_type"
                  >
                    <option value="">Select</option>
                    <option value="o">Online</option>
                    <option value="l">Local</option>
                    <option value="b">Both</option>
                    <option value="r">Reward</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="short_description"
                  >
                    Short Description
                  </label>
                  <textarea
                    {...register("shortDescription")}
                    className={`form-control ${
                      errors.shortDescription && "invalid-form-input"
                    }`}
                    type="text"
                    id="short_description"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="description"
                  >
                    Description <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    {...register("description", { required: true })}
                    className={`form-control ${
                      errors.description && "invalid-form-input"
                    }`}
                    type="text"
                    id="description"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tiers"
                  >
                    Tiers
                  </label>
                  <select
                    {...register("tierId")}
                    className={`form-select`}
                    id="tiers"
                  >
                    <option value="">Select</option>
                    {geTtiers.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.name} | {String(value.active)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="free_offers"
                  >
                    Free Offers
                  </label>
                  <select
                    {...register("mappedOfferId")}
                    className={`form-select ${
                      errors.mappedOfferId && "invalid-form-input"
                    }`}
                    id="free_offers"
                  >
                    <option value="">Select</option>
                    {getfreeOffers.map((value, index) => (
                      <option key={value.id} value={value.id}>
                        {value.advertiserName} | {value.commission}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="commission"
                  >
                    Commission
                  </label>
                  <input
                    {...register("commission")}
                    className={`form-control`}
                    type="text"
                    id="commission"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="expiry_date"
                  >
                    Expiry Date <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("expiryDate", { required: true })}
                    className={`form-control ${
                      errors.expiryDate && "invalid-form-input"
                    }`}
                    type="date"
                    id="expiry_date"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="category"
                  >
                    Category
                  </label>
                  <select
                    {...register("category")}
                    className={`form-select ${
                      errors.category && "invalid-form-input"
                    }`}
                    id="category"
                  >
                    <option value="">Select</option>
                    {getAllcategories.map((value) => (
                      <option key={value.id} value={value.name}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offerVisibilityLevel"
                  >
                    Offer Visibility Level
                  </label>
                  <select
                    {...register("offerVisibilityLevel")}
                    className={`form-select ${
                      errors.offerVisibilityLevel && "invalid-form-input"
                    }`}
                    id="offerVisibilityLevel"
                    value={
                      OFFER_VISIBILITY_OPTIONS.filter(
                        (option) => option.default
                      )[0].value
                    }
                  >
                    <option value="">Select</option>
                    {OFFER_VISIBILITY_OPTIONS.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div
                className="col-md-4 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-group form-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("autoApproval")}
                    className="form-check-input"
                    type="checkbox"
                    id="auto_approval"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="auto_approval"
                    style={{ lineHeight: "1" }}
                  >
                    Auto Approval
                  </label>
                </div>
              </div>
              <div
                className="col-md-4 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div
                  style={{ marginLeft: "12px", marginTop: "30px" }}
                  className="d-flex align-items-center gap-3"
                >
                  <input
                    {...register("skipOfferDetail")}
                    className={`form-check-input ${
                      errors.isIosOffer && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="skipOffer"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="skipOffer"
                    style={{ lineHeight: "1" }}
                  >
                    Skip Offer Details
                  </label>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                  <div
                    className="col-md-6 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isActive")}
                        className={`form-check-input ${
                          errors.isActive && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="is_active"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="is_active"
                        style={{ lineHeight: "1" }}
                      >
                        Is Active
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-6 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("preActivated")}
                        className="form-check-input"
                        type="checkbox"
                        id="pre_activated"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="pre_activated"
                        style={{ lineHeight: "1" }}
                      >
                        Pre activated
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isIosOffer")}
                        className={`form-check-input ${
                          errors.isIosOffer && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="ios_offer"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="ios_offer"
                        style={{ lineHeight: "1" }}
                      >
                        iOS Offer
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isAndroidOffer")}
                        className={`form-check-input ${
                          errors.isAndroidOffer && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="android_offer"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="android_offer"
                        style={{ lineHeight: "1" }}
                      >
                        Android Offer
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-end"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="form-groupform-check d-flex align-items-center gap-3 ">
                      <input
                        {...register("isMemberBenefit")}
                        className={`form-check-input ${
                          errors.isMemberBenefit && "invalid-form-input"
                        }`}
                        type="checkbox"
                        id="isMemberBenefit"
                      />
                      <label
                        className="form-check-label fw-bold text-secondary"
                        htmlFor="isMemberBenefit"
                        style={{ lineHeight: "1" }}
                      >
                        Member Offer
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="affiliate_partner"
                  >
                    Affiliate Partner{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("affiliatePartner", { required: true })}
                    className={`form-select ${
                      errors.affiliatePartner && "invalid-form-input"
                    }`}
                    id="affiliate_partner"
                  >
                    <option value="">Select</option>
                    <option value="CJ">CJ</option>
                    <option value="vcommision">vcommision</option>
                    <option value="CjProduct">CjProduct</option>
                    <option value="ImpactNetwork">ImpactNetwork</option>
                    <option value="Rakuten">Rakuten</option>
                    <option value="Rakuten CLO">Rakuten CLO</option>
                    <option value="Direct">Direct</option>
                    <option value="PapperJam">PapperJam</option>
                    <option value="Admitad">Admitad</option>
                    <option value="Coupon">Coupon</option>
                    <option value="ad_based">Ad Based</option>
                    <option value="Awin">Awin</option>
                  </select>
                </div>
              </div>
              {watch("affiliatePartner") === "Coupon" && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label fw-bold text-secondary user-select-none"
                      htmlFor="coupon_type"
                    >
                      Coupon Type <small className="text-danger fs-6">*</small>
                    </label>
                    <select
                      {...register("couponType", { required: true })}
                      className={`form-select ${
                        errors.couponType && "invalid-form-input"
                      }`}
                      id="coupon_type"
                    >
                      <option value="">Select</option>
                      <option value="Static">Static</option>
                      <option value="List">List</option>
                    </select>
                  </div>
                </div>
              )}
              {watch("affiliatePartner") === "Coupon" &&
                watch("couponType") === "Static" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="couponCode"
                      >
                        Coupon Code{" "}
                        <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("couponCode", { required: true })}
                        className={`form-control ${
                          errors.couponCode && "invalid-form-input"
                        }`}
                        type="text"
                        id="couponCode"
                      />
                    </div>
                  </div>
                )}
              {watch("affiliatePartner") === "Coupon" &&
                watch("couponType") === "List" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        style={{ minWidth: "300px" }}
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="rewardCampaignId"
                      >
                        Reward Campaign Id{" "}
                        <small className="text-danger fs-6">*</small>
                      </label>
                      <input
                        {...register("rewardCampaignId", { required: true })}
                        className={`form-control ${
                          errors.rewardCampaignId && "invalid-form-input"
                        }`}
                        type="text"
                        id="rewardCampaignId"
                      />
                    </div>
                  </div>
                )}
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerId"
                  >
                    Offer Id
                  </label>
                  <input
                    disabled={
                      ["Direct", ""].includes(watch("affiliatePartner"))
                        ? true
                        : false
                    }
                    {...register("offerId", {
                      required: ["Direct", ""].includes(
                        watch("affiliatePartner")
                      )
                        ? false
                        : true,
                    })}
                    className={`form-control ${
                      errors.offerId &&
                      (["Direct", ""].includes(watch("affiliatePartner"))
                        ? false
                        : true) &&
                      "invalid-form-input"
                    }`}
                    type="text"
                    id="offerId"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="file_type"
                  >
                    File Type
                  </label>
                  <select disabled className={`form-select`} id="file_type">
                    <option value="">Select</option>
                    <option value="image">Image</option>
                    <option value="logo">Logo</option>
                    <option value="bigImage">Big Image</option>
                    <option value="smallImage">Small Image</option>
                    <option value="smallLogoImage">Small Logo Image</option>
                    <option value="offerWallLogo">Offer Wall Logo</option>
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="image"
                  >
                    File
                  </label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      type="file"
                      className="form-control"
                      id="image"
                    />
                    <button disabled className="btn btn-primary" type="button">
                      Upload
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p className="text-primary">
                  Before uploading the file, you have to click on the save and
                  continue editing option, after that you can upload the file.
                </p>
              </div>
            </div>

            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} /> */}
            {/* <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_wall_big_card_position"
                  >
                    Offer Wall Big Card Position
                  </label>
                  <input
                    {...register("offerWallBigCardPosition")}
                    className={`form-control ${
                      errors.offerWallBigCardPosition && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_wall_big_card_position"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_wall_logo_card_position"
                  >
                    Offer Wall Logo Card Position
                  </label>
                  <input
                    {...register("offerWallLogoCardPosition")}
                    className={`form-control ${
                      errors.offerWallLogoCardPosition && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_wall_logo_card_position"
                  />
                </div>
              </div>
            </div> */}
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="popularity"
                  >
                    Popularity <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("popularity", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.popularity && "invalid-form-input"
                    }`}
                    type="number"
                    id="popularity"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="click_url"
                  >
                    Click URL
                  </label>
                  <input
                    {...register("clickUrl")}
                    className={`form-control ${
                      errors.clickUrl && "invalid-form-input"
                    }`}
                    type="text"
                    id="click_url"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="latitude"
                  >
                    Latitude
                  </label>
                  <input
                    {...register("latitude")}
                    className={`form-control ${
                      errors.latitude && "invalid-form-input"
                    }`}
                    type="number"
                    id="latitude"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="longitude"
                  >
                    Longitude
                  </label>
                  <input
                    {...register("longitude")}
                    className={`form-control ${
                      errors.longitude && "invalid-form-input"
                    }`}
                    type="number"
                    id="longitude"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="placeholder"
                  >
                    Placeholder
                  </label>
                  <input
                    {...register("placeholder")}
                    className={`form-control ${
                      errors.placeholder && "invalid-form-input"
                    }`}
                    type="text"
                    id="placeholder"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_title"
                  >
                    Cashback Title
                  </label>
                  <input
                    {...register("cashBackTitle")}
                    className={`form-control ${
                      errors.cashBackTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="cashback_title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="strike_through_cash_back_title"
                  >
                    Strike Through Cash Back Title
                  </label>
                  <input
                    {...register("strikeThroughCashBackTitle")}
                    className={`form-control ${
                      errors.strikeThroughCashBackTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="strike_through_cash_back_title"
                  />
                </div>
              </div>
            </div>
            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} /> */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_max_times"
                  >
                    Cashback Max Times{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("cashbackMaxTimes", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.cashbackMaxTimes && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_max_times"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="timesclub_commission"
                  >
                    Timesclub Commission
                  </label>
                  <input
                    {...register("timesclubCommission")}
                    className={`form-control ${
                      errors.timesclubCommission && "invalid-form-input"
                    }`}
                    type="number"
                    id="timesclub_commission"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_min_amount"
                  >
                    Cashback Min Amount{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("cashbackMinAmount", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.cashbackMinAmount && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_min_amount"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="cashback_max_amount"
                  >
                    Cashback Max Amount
                  </label>
                  <input
                    {...register("cashbackMaxAmount")}
                    className={`form-control ${
                      errors.cashbackMaxAmount && "invalid-form-input"
                    }`}
                    type="number"
                    id="cashback_max_amount"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="monthly_cashback_limit"
                  >
                    Monthly Cashback Limit
                  </label>
                  <input
                    min="0"
                    {...register("monthlyCashbackLimit")}
                    className={`form-control ${
                      errors.monthlyCashbackLimit && "invalid-form-input"
                    }`}
                    type="number"
                    id="monthly_cashback_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_link_expiration_days"
                  >
                    Cashback Priority
                  </label>
                  <input
                    min="1"
                    defaultValue={999}
                    {...register("cashbackPriority")}
                    className={`form-control ${
                      errors.cashbackPriority && "invalid-form-input"
                    }`}
                    type="text"
                    id="offer_link_expiration_days"
                  />
                </div>
              </div>
            </div>

            {/* <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div
                className="col-md-12 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("newSignUpFlow")}
                    className={`form-check-input ${
                      errors.newSignUpFlow && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="new_signup_flow"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="new_signup_flow"
                    style={{ lineHeight: "1" }}
                  >
                    New Signup Flow
                  </label>
                </div>
              </div>
              {watch("newSignUpFlow") === true && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="new_sign_up_text"
                      >
                        New Sign Up Text
                      </label>
                      <input
                        {...register("newSignUpText")}
                        className={`form-control ${
                          errors.newSignUpText && "invalid-form-input"
                        }`}
                        type="text"
                        id="new_sign_up_text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none w-100"
                        htmlFor="new_sign_up_flow_sequence_no"
                      >
                        New Sign Up Flow Sequence No
                      </label>
                      <input
                        {...register("newSignUpFlowSequenceNumber")}
                        className={`form-control ${
                          errors.newSignUpFlowSequenceNumber &&
                          "invalid-form-input"
                        }`}
                        type="number"
                        id="new_sign_up_flow_sequence_no"
                      />
                    </div>
                  </div>
                </>
              )}
            </div> */}
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("firstBankOffer")}
                    className={`form-check-input ${
                      errors.firstBankOffer && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="first_bank_offer"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="first_bank_offer"
                    style={{ lineHeight: "1" }}
                  >
                    First Bank Offer
                  </label>
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("locationBased")}
                    className={`form-check-input ${
                      errors.locationBased && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="location_based"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="location_based"
                    style={{ lineHeight: "1" }}
                  >
                    Location Based
                  </label>
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("locationMandatory")}
                    className={`form-check-input ${
                      errors.locationMandatory && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="location_mandatory"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="location_mandatory"
                    style={{ lineHeight: "1", width: "auto" }}
                  >
                    Location Mandatory
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_title"
                  >
                    Sponsor Title
                  </label>
                  <input
                    {...register("sponserTitle")}
                    className={`form-control ${
                      errors.sponserTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_sub_title_id"
                  >
                    Sponsor Sub Title Id
                  </label>
                  <input
                    {...register("sponserSubTitle")}
                    className={`form-control ${
                      errors.sponserSubTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_sub_title_id"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_logo"
                  >
                    Sponsor Logo
                  </label>
                  <input
                    {...register("sponserLogo")}
                    className={`form-control ${
                      errors.sponserLogo && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_logo"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="sponsor_link"
                  >
                    Sponsor Link
                  </label>
                  <input
                    {...register("sponserLink")}
                    className={`form-control ${
                      errors.sponserLink && "invalid-form-input"
                    }`}
                    type="text"
                    id="sponsor_link"
                  />
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <button
                  type="button"
                  className="btn btn-success rounded-right-none"
                  style={{
                    whiteSpace: "nowrap",
                    height: "3rem",
                  }}
                  disabled
                >
                  Create FAQ for this offer
                </button>
              </div>
              <div className="col-md-12 mt-2">
                <p className="text-primary">
                  Before creating FAQ'S, you have to click on the save and
                  continue editing option, after that you can create FAQ's.
                </p>
              </div>

              {!!linkedGroupsList?.length && (
                <div className="col-md-12">
                  <Accordion sx={{ margin: "20px 0px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className="col-md-12">
                        <label
                          className="form-label fw-bold text-secondary user-select-none mb-0"
                          htmlFor="linked_groups"
                          style={{ lineHeight: "1" }}
                        >
                          Linked Groups
                        </label>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <div className="col-md-12">
                          {linkedGroupsList.map((value, index) => {
                            return (
                              <OfferGroup
                                data={value}
                                index={index}
                                removeLinkedGroups={removeLinkedGroups}
                                updatedSortedLinkedGroups={
                                  updatedSortedLinkedGroups
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="linked_groups"
                  >
                    Linked Groups
                  </label>

                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="linkedGroups"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        styles={selectStyle}
                        inputRef={ref}
                        closeMenuOnSelect={false}
                        value={getAllOffergroups
                          .map((store) => ({
                            label: `${store.groupTitle} | ${store.templateType} | ${store.groupType}`,
                            value: store.id,
                          }))
                          .filter((c) => value.includes(c.value))}
                        onChange={(val) => {
                          handleLinkedGroupChnage(onChange, val);
                        }}
                        options={getAllOffergroups.map((store) => ({
                          label: `${store.groupTitle} | ${store.templateType} | ${store.groupType}`,
                          value: store.id,
                        }))}
                        isMulti
                        isDisabled={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <p className="text-primary">
                  Before adding Linked groups, you have to click on the save and
                  continue editing option, after that you can add Linked groups.
                </p>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="merchant_name"
                  >
                    Merchant Name
                  </label>
                  <input
                    {...register("merchantName")}
                    className={`form-control ${
                      errors.merchantName && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_name"
                  />
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("displayMerchantName")}
                    className="form-check-input"
                    type="checkbox"
                    id="display_merchant_name"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary "
                    htmlFor="display_merchant_name"
                    style={{ lineHeight: "1", width: "auto" }}
                  >
                    Display Merchant Name
                  </label>
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row mt-4">
              <div className="col-md-12">
                <label
                  className="form-label fw-bold text-secondary user-select-none mb-0"
                  htmlFor="merchant_name"
                  style={{ lineHeight: "1" }}
                >
                  Tags
                </label>
              </div>
              <div className="col-auto mb-2">
                <div className="d-flex gap-2 flex-wrap">
                  {tagList.map((valu, index) => (
                    <span
                      key={index}
                      className="badge py-2 px-3 d-flex align-items-center gap-2"
                      style={{ background: "#040721", color: "#f8d269" }}
                    >
                      {valu.type} | {valu.value}
                      <span
                        className="badge bg-danger py-2 px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeTag(index)}
                      >
                        &#x2715;
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <div className="form-group">
                  <select
                    value={handleTagValueChange}
                    onChange={(e) => sethandleTagValueChange(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select tag name</option>
                    <option value="normal">Normal</option>
                    <option value="location">location</option>
                    <option value="PRO">PRO</option>
                    <option value="CLO">CLO</option>
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group d-flex">
                  <input
                    value={handleTagNameChange}
                    onChange={(e) => sethandleTagNameChange(e.target.value)}
                    type="text"
                    placeholder="Enter tag value"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-1">
                <button
                  onClick={handleTagSubmit}
                  type="button"
                  className="btn btn-success rounded-right-none"
                  style={{
                    whiteSpace: "nowrap",
                    height: "3rem",
                  }}
                >
                  Add Tags
                </button>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="terms_and_conditions"
                  >
                    Terms and Conditions
                  </label>
                  <textarea
                    rows="6"
                    {...register("termsConditions")}
                    className={`form-control ${
                      errors.termsConditions && "invalid-form-input"
                    }`}
                    type="text"
                    id="terms_and_conditions"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="offer_link_expiration_days"
                  >
                    Offer Link Expiration Days
                  </label>
                  <input
                    {...register("offerLinkExpirationDays")}
                    className={`form-control ${
                      errors.offerLinkExpirationDays && "invalid-form-input"
                    }`}
                    type="number"
                    id="offer_link_expiration_days"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="merchant_id"
                  >
                    Merchant Id
                  </label>
                  <select
                    {...register("merchantId")}
                    className={`form-select ${
                      errors.merchantId && "invalid-form-input"
                    }`}
                    id="merchant_id"
                  >
                    <option value="">Select</option>
                    {merchantList.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.merchant_name} | {data.id}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input
                    {...register("merchantId")}
                    className={`form-control ${
                      errors.merchantId && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_id"
                  /> */}
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
              <button
                className="btn btn-warning py-2 px-5"
                name="savegoanother"
              >
                Save And Add Another
              </button>
              <button className="btn btn-success py-2 px-5" name="savecontinue">
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddOffers;
