import { Container } from "../../components/dnd/Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLayoutEffect, useState } from "react";

const DragList = ({ setList, data }) => {
  const [shouldRender, setShouldRender] = useState(false);

  const buildCardData = () => {
    const cardsById = {};
    const cardsByIndex = [];
    for (let i = 0; i < data.length; i += 1) {
      const card = {
        id: i,
        text: data[i].offerName,
        // text: `My offer - ${data[i].offerId}`,
        offerId: data[i].offerId,
      };
      cardsById[card.id] = card;
      cardsByIndex[i] = card;
    }
    return {
      cardsById,
      cardsByIndex,
    };
  };

  const getSortedData = (data) => {
    if (data) {
      const sortedOffer = data.cardsByIndex.map((v, i) => {
        return { offerId: v.offerId, position: i, text: v.text };
      });
      sortedOffer.current = sortedOffer;
      setList(sortedOffer);
    }
  };

  useLayoutEffect(() => {
    setShouldRender(true);
  }, [data]);

  return (
    <DndProvider backend={HTML5Backend}>
      {shouldRender && (
        <Container getSortedData={getSortedData} dataList={buildCardData()} />
      )}
    </DndProvider>
  );
};
export default DragList;
