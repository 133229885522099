import React, { useState, useEffect, useLayoutEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import localforage from "localforage";
import {
  TextField,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  getOffersDataById,
  getOffersList,
  updateOffersRecord,
} from "../../../services/offers";
import {
  filterList,
  searchExactList,
  startLoader,
  stopLoader,
  filterExpiredOffers,
} from "../../../libs/utils";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

function ListOffers({ type = "" }) {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [SequenceKey, setSequenceKey] = useState("");
  const [selectOrder, setSelectOrder] = useState("");
  const [prevRows, setPrevRows] = useState([]);
  const [activeStatus, setActiveStatus] = useState("");
  const [expiryStatus, setExpiryStatus] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const handleSelectOrderChange = (event) => {
    setSelectOrder(event.target.value);
    if (event.target.value) {
      if (expiryStatus) {
        filterExpiredOffers(
          searchExactList(
            searchExactList(prevRows, activeStatus),
            event.target.value
          )
        );
        return;
      }
      setRows(
        searchExactList(
          searchExactList(prevRows, activeStatus),
          event.target.value
        )
      );
      return;
    }
    setRows(prevRows);
  };

  const handleActiveStatus = (event) => {
    setActiveStatus(event.target.value);
    if (event.target.value) {
      if (expiryStatus) {
        setRows(
          filterExpiredOffers(
            searchExactList(
              searchExactList(prevRows, selectOrder),
              event.target.value
            )
          )
        );
        return;
      }
      setRows(
        searchExactList(
          searchExactList(prevRows, selectOrder),
          event.target.value
        )
      );
      return;
    }
    setRows(prevRows);
  };

  const handleExpiredStatus = (event) => {
    setExpiryStatus(event.target.value);
    if (event.target.value) {
      setRows(
        filterExpiredOffers(
          searchExactList(searchExactList(prevRows, selectOrder)),
          activeStatus
        )
      );
      return;
    }
    setRows(prevRows);
  };

  let columns = [];
  if (SequenceKey === "") {
    columns = [
      {
        id: "AdvertiserName",
        label: "Advertiser Name",
        minWidth: 300,
      },
      { id: "title", label: "Title", minWidth: 300, enableSort: true },
      {
        id: "IsActive",
        label: "Active",
        minWidth: 100,
        align: "center",
      },
      {
        id: "Commission",
        label: "Commission",
        minWidth: 100,
        align: "center",
        enableSort: true,
      },
      {
        id: "Category",
        label: "Category",
        minWidth: 100,
        align: "center",
        enableSort: true,
      },
      {
        id: "ExpiryDate",
        label: "Expiry Date",
        minWidth: 160,
        align: "center",
        enableSort: true,
      },
      {
        id: "AffiliatePartner",
        label: "Affiliate Partner",
        minWidth: 70,
        align: "center",
        enableSort: true,
      },
      {
        id: "PromoType",
        label: "Promo Type",
        minWidth: 50,
        align: "center",
      },
      {
        id: "Popularity",
        label: "Popularity",
        minWidth: 50,
        align: "center",
        enableSort: true,
      },
      {
        id: "PriorityOfOffer",
        label: "Priority",
        minWidth: 40,
        align: "center",
        enableSort: true,
      },

      {
        id: "Tier",
        label: "Tier",
        minWidth: 35,
        align: "center",
      },
      {
        id: "applicableStates",
        label: "Location",
        minWidth: 100,
        align: "center",
      },
    ];
  }

  if (SequenceKey !== "") {
    columns = [
      {
        id: "AdvertiserName",
        label: "Advertiser Name",
        minWidth: 300,
      },
      { id: "title", label: "Title", minWidth: 300 },
      {
        id: "IsActive",
        label: "Active",
        minWidth: 100,
        align: "center",
      },
      {
        id: "Commission",
        label: "Commission",
        minWidth: 100,
        align: "center",
      },
      {
        id: "Category",
        label: "Category",
        minWidth: 100,
        align: "center",
      },

      {
        id: "ExpiryDate",
        label: "Expiry Date",
        minWidth: 160,
        align: "center",
      },
      {
        id: "AffiliatePartner",
        label: "Affiliate Partner",
        minWidth: 70,
        align: "center",
      },
      {
        id: "PromoType",
        label: "Promo Type",
        minWidth: 50,
        align: "center",
      },
      {
        id: "Popularity",
        label: "Popularity",
        minWidth: 50,
        align: "center",
      },
      {
        id: "PriorityOfOffer",
        label: "Priority",
        minWidth: 40,
        align: "center",
      },
      {
        id: "SequenceKey",
        label: SequenceKey,
        minWidth: 35,
        align: "center",
      },
      {
        id: "geoFencingEnabled",
        label: "Geo-Fencing",
        minWidth: 100,
        align: "center",
        enableSort: true,
      },
      {
        id: "applicableStates",
        label: "Applicable States",
        minWidth: 100,
        align: "center",
        enableSort: true,
      },
    ];
  }

  function createData(
    AdvertiserNameString,
    AdvertiserName,
    title,
    IsActive,
    Commission,
    Category,
    ExpiryDate,
    AffiliatePartner,
    PromoType,
    Popularity,
    PriorityOfOffer,
    Tier,
    SequenceKey,
    id,
    expired,
    activeStatus,
    geoFencingEnabled,
    applicableStates
  ) {
    return {
      AdvertiserNameString,
      AdvertiserName,
      title,
      IsActive,
      Commission,
      Category,
      ExpiryDate,
      AffiliatePartner,
      PromoType,
      Popularity,
      PriorityOfOffer,
      Tier,
      SequenceKey,
      id,
      expired,
      activeStatus,
      geoFencingEnabled,
      applicableStates,
    };
  }

  const setOfferActiveStatus = (id, checked) => {
    stopLoader();
    getOffersDataById(id)
      .then((response) => {
        let data = {
          id: id,
          listNo: 0,
          linkedGroups: response.linkedGroups,
          advertiserName: response.advertiserName,
          title: response.title,
          subTitle: response.subTitle,
          promoType: response.promoType,
          shortDescription: response.shortDescription,
          description: response.description,
          tierId: response.tierId,
          mappedOfferId: response.mappedOfferId,
          commission: response.commission,
          expiryDate: response.expiryDate,
          category: response.category,
          affiliatePartner: response.affiliatePartner,
          offerId: response.offerId,
          offerWallBigCardPosition: response.offerWallBigCardPosition,
          offerWallLogoCardPosition: response.offerWallLogoCardPosition,
          popularity: response.popularity,
          clickUrl: response.clickUrl,
          latitude: response.latitude,
          longitude: response.longitude,
          placeholder: response.placeholder,
          cashBackTitle: response.cashBackTitle,
          strikeThroughCashBackTitle: response.strikeThroughCashBackTitle,
          cashbackMaxTimes: response.cashbackMaxTimes,
          timesclubCommission: response.timesclubCommission,
          cashbackMinAmount: response.cashbackMinAmount,
          cashbackMaxAmount: response.cashbackMaxAmount,
          monthlyCashbackLimit: response.monthlyCashbackLimit,
          cashbackPriority: response.cashbackPriority,
          isActive: response.isActive,
          isIosOffer: response.isIosOffer,
          isAndroidOffer: response.isAndroidOffer,
          newSignUpFlow: response.newSignUpFlow,
          newSignUpText: response.newSignUpText,
          newSignUpFlowSequenceNumber: response.newSignUpFlowSequenceNumber,
          firstBankOffer: response.firstBankOffer,
          locationBased: response.locationBased,
          locationMandatory: response.locationMandatory,
          sponserTitle: response.sponserTitle,
          sponserSubTitle: response.sponserSubTitle,
          sponserLogo: response.sponserLogo,
          sponserLink: response.sponserLink,
          linked_groups: response.linked_groups,
          merchantName: response.merchantName,
          displayMerchantName: response.displayMerchantName,
          preActivated: response.preActivated,
          termsConditions: response.termsConditions,
          offerLinkExpirationDays: response.offerLinkExpirationDays,
          merchantId: response.merchantId,
          logo: response.logo,
          bigImage: response.bigImage,
          smallImage: response.smallImage,
          image: response.image,
          smallLogoImage: response.smallLogoImage,
          offerWallLogoCardImage: response.offerWallLogoCardImage,
          tags: response.tags,
          couponType: response.couponType,
          couponCode: response.couponCode,
          rewardCampaignId: response.rewardCampaignId,
          geoFencingEnabled: response.geoFencingEnabled,
          applicableStates: response.applicableStates,
        };
        data.isActive = checked;
        updateOffersRecord(data)
          .then(() => {
            setRows((rows) => {
              const updatedRows = rows.filter((row) => row.id !== id);
              return updatedRows;
            });
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch(function (error) {
        throw error;
      });
  };
  const handleCategories = (categories) => {
    if (categories.length > 1) {
      return categories.join(" | ");
    }
    return categories;
  };

  const handleStateCodes = (stateCodes) => {
    if (!stateCodes) {
      return;
    }
    if (stateCodes.length > 1) {
      return stateCodes.join(" | ");
    }
    return stateCodes;
  };

  const dataListCreator = (response) => {
    if (response?.length > 0 && typeof response === "object") {
      return response.map((data) => {
        const currentDate = moment().format("YYYY-MM-DD");
        const expiryDate = moment(data.expiryDate);

        const daysLeft = expiryDate.diff(currentDate, "days");
        return createData(
          data.advertiserName,
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar src={data.logo} />
              &nbsp; &nbsp;{" "}
              <Link
                to={`/app/offer/${data.id}`}
                style={{ color: "black", textDecoration: "none" }}
              >
                {data.advertiserName}{" "}
                {moment(data.expiryDate).unix() < moment().unix() && (
                  <span className="badge bg-danger">Expired</span>
                )}
                {data.isActive && daysLeft < 30 && daysLeft > 0 && (
                  <span className="badge bg-danger">Expiring soon </span>
                )}
              </Link>
            </div>
          </>,
          data.title,
          <>
            <Switch
              checked={data.isActive}
              onChange={(e) => setOfferActiveStatus(data.id, e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>,
          data.commission,
          handleCategories(data.category),

          moment(data.expiryDate).format("MMM D, YYYY"),
          data.affiliatePartner,
          <>
            {data.promoType === "o" && <>Online</>}
            {data.promoType === "l" && <>Local</>}
            {data.promoType === "b" && <>Both</>}
          </>,
          data.popularity,
          data.cashbackPriority,
          data.tierId,
          <>
            {SequenceKey === "cardNo" && data.cardNo}
            {SequenceKey === "bigCardNo" && data.bigCardNo}
            {SequenceKey === "smallCardNo" && data.smallCardNo}
            {SequenceKey === "listNo" && data.listNo}
            {SequenceKey === "newSignUpFlowSequenceNumber" &&
              data.newSignUpFlowSequenceNumber}
            {SequenceKey === "logoCardNo" && data.logoCardNo}
          </>,
          data.id,
          moment(data.expiryDate).unix() < moment().unix() && "Expired",
          data.isActive === true ? "active" : "inactive",
          data.geoFencingEnabled.toString(),
          handleStateCodes(data.applicableStates)
        );
      });
    } else {
      return [];
    }
  };

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  useEffect(() => {
    setLoading(true);
    // localforage.getItem("@offers", (error, data) => {
    //   if (data) {
    //     if (data?.length > 0 && typeof data === "object") {
    //       setRows(dataListCreator(data));
    //       setPrevRows(dataListCreator(data));
    //     } else {
    //       setRows([]);
    //     }
    //   }
    // });
    getOffersList()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          let otherOffers = [];
          let expiringOffers = [];
          let totalOffers = [];
          let expiredOffers = [];

          response.forEach((obj) => {
            const currentDate = moment().format("YYYY-MM-DD");
            const expiryDate = moment(obj.expiryDate);

            if (expiryDate.unix() < moment().unix() || !obj?.isActive) {
              expiredOffers = [...expiredOffers, obj];
              return;
            }
            const daysLeft = expiryDate.diff(currentDate, "days");

            if (obj.isActive && daysLeft < 30 && daysLeft > 0) {
              expiringOffers = [...expiringOffers, obj];
              return;
            }
            otherOffers = [...otherOffers, obj];
          });
          totalOffers = [...expiringOffers, ...otherOffers];
          const appliedOffers =
            type === "expired" ? expiredOffers : totalOffers;
          setRows(dataListCreator(appliedOffers));
          setPrevRows(dataListCreator(appliedOffers));
        } else {
          setRows([]);
        }
        localforage.setItem("@offers", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, [type]);

  useLayoutEffect(() => {
    startLoader();
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Offer List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <FormControl variant="standard" sx={{ mr: 3, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Expiry Status
              </InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={expiryStatus}
                onChange={handleExpiredStatus}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ mr: 3, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Active Status
              </InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={activeStatus}
                onChange={handleActiveStatus}
                label="Age"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ mr: 3, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Affiliate Partner
              </InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectOrder}
                onChange={handleSelectOrderChange}
                label="Age"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="CJ">CJ</MenuItem>
                <MenuItem value="vcommision">vcommision</MenuItem>
                <MenuItem value="CjProduct">CjProduct</MenuItem>
                <MenuItem value="ImpactNetwork">ImpactNetwork</MenuItem>
                <MenuItem value="Rakuten">Rakuten</MenuItem>
                <MenuItem value="Rakuten CLO">Rakuten CLO</MenuItem>
                <MenuItem value="Direct">Direct</MenuItem>
                <MenuItem value="PapperJam">PapperJam</MenuItem>
                <MenuItem value="Admitad">Admitad</MenuItem>
                <MenuItem value="Coupon">Coupon</MenuItem>
                <MenuItem value="ad_based">Ad Based</MenuItem>
                <MenuItem value="Awin">Awin</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
            {type !== "expired" && (
              <>
                <Tooltip title="Choose Option">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon sx={{ width: 32, height: 32 }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "#f9da8792",
                        color: "#000",
                      },
                    }}
                  >
                    <Link to="/app/offer/0" style={{ color: "#040721" }}>
                      Add New Offer
                    </Link>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(
            searchExactList(searchExactList(rows, selectOrder), activeStatus),
            searchKey
          )}
          columns={columns}
        />
      </Box>
    </React.Fragment>
  );
}

export default ListOffers;
