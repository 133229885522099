import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";

import CustomTable from "../../../components/CustomTable";
import { filterList } from "../../../libs/utils";
import { getAllActivities } from "../../../services/timesPoint/tpEarnActivity";

const ActivityList = () => {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    {
      id: "title",
      label: "Title",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "displayPoints",
      label: "Display Points",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "actionButtonTitle",
      label: "Action Button Title",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "sequenceOrder",
      label: "Sequence Order",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    // {
    //   id: "starActivity",
    //   label: "Star Activity",
    //   minWidth: 100,
    //   sortable: true,
    //   align: "left",
    //   enableSort: true,
    // },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "left",
    },
  ];

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataGenerator = ({
    title,
    displayPoints,
    actionButtonTitle,
    sequenceOrder,
    // starActivity,
    _id,
  }) => {
    return {
      title,
      displayPoints,
      actionButtonTitle,
      sequenceOrder,
      // starActivity: starActivity ? "Yes" : "No",
      Action: (
        <Link
          to={`/app/earn-activity/${_id}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  const fetchEarnActivities = async () => {
    setLoading(true);
    const allActivitiesApiRes = await getAllActivities();
    const { success, data, error } = allActivitiesApiRes || {};
    setLoading(false);
    if (success) {
      setRows(data?.map((earnActivity) => dataGenerator(earnActivity)));
      return;
    }
    Swal.fire({
      title: "Oops",
      text: error,
      icon: "warning",
      confirmButtonText: "Ok",
    });
  };

  useEffect(() => {
    fetchEarnActivities();
  }, []);
  return (
    <>
      <Box sx={{ px: 6 }} className={"main-box"}>
        <div className={"bankAddRuleHeader mb-3"}>
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Earn Activity List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/earn-activity/0" style={{ color: "#040721" }}>
                  Add New Earn Activity
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>

        <CustomTable
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default ActivityList;
